import React, { useCallback, useState } from "react";
import {
  Box,
  FormControl,
  styled,
  TextField,
  LocalizationProvider,
  DatePicker,
  Typography,
  DragZone,
  CardPdf,
  dayjs,
  //@ts-ignore
} from "@enerbit/base";
// @ts-ignore
import { AdapterDayjs } from "@enerbit/base";
import { onSelectDocument } from "../../../../services/uploadFile";
import { useEffect } from "react";
import { FileBlobType } from "../../../../models/base";

interface Props {
  title: string;
  label: string;
  sectionMeter: string;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sectionMeter: string
  ) => void;
}

const MeterItem = ({
  title,
  label,
  sectionMeter,
  formData,
  setFormData,
  handleInputChange,
}: Props) => {
  const [fileInfo, setFileInfo] = useState<Blob | string>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filePath, setFilePath] = useState<Blob | string>();

  useEffect(() => {
    setFormData({
      ...formData,
      [sectionMeter]: {
        ...formData[sectionMeter],
        certificate_path: filePath,
      },
    });
  }, [filePath]);

  const uploadFile = async (file: Blob | string): Promise<void> => {
    setIsLoadingFile(true);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess);
      setFilePath(res);
    } catch (err) {
      // onClearFile();
    } finally {
      setIsLoadingFile(false);
    }
  };

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    setIsSuccess(false);
    setFileInfo(acceptedFiles[0]);
    uploadFile(acceptedFiles[0]);
  }, []);

  const onClearFile = (): void => {
    setIsSuccess(false);
    setFormData({
      ...formData,
      [sectionMeter]: {
        ...formData[sectionMeter],
        certificate_path: "",
      },
    });
    setProgress(0);
  };

  return (
    <Box>
      <Typography
        color="primary"
        sx={{
          fontWeight: "300",
          my: 3,
        }}
        variant="h5"
      >
        {title}
      </Typography>
      <FormContainer grid_columns={3}>
        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>{label}</CustomLabel>
          <TextField
            placeholder="Número del certificado activa importación"
            type="text"
            name="certificate_number"
            sx={{ "& fieldset": { borderRadius: "12px" } }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, sectionMeter)
            }
            value={formData[sectionMeter]?.certificate_number}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>Ente emisor</CustomLabel>
          <TextField
            placeholder="Ente emisor"
            type="text"
            name="issuer_entity"
            sx={{ "& fieldset": { borderRadius: "12px" } }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, sectionMeter)
            }
            value={formData[sectionMeter]?.issuer_entity}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: "20px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomLabel>Fecha de emisión</CustomLabel>
            <DatePicker
              format="YYYY/MM/DD"
              value={dayjs(formData[sectionMeter]?.issue_date) || ""}
              maxDate={dayjs()}
              onChange={(newValue: Date | any) => {
                setFormData({
                  ...formData,
                  [sectionMeter]: {
                    ...formData[sectionMeter],
                    issue_date: newValue?.format("YYYY-MM-DD"),
                  },
                });
              }}
              slotProps={{
                textField: {
                  sx: {
                    "& fieldset": { borderRadius: "14px" },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth sx={{ mt: "20px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomLabel>Fecha de calibración</CustomLabel>
            <DatePicker
              format="YYYY/MM/DD"
              maxDate={dayjs()}
              value={dayjs(formData[sectionMeter]?.calibration_date) || ""}
              onChange={(newValue: Date | any) => {
                setFormData({
                  ...formData,
                  [sectionMeter]: {
                    ...formData[sectionMeter],
                    calibration_date: newValue?.format("YYYY-MM-DD"),
                  },
                });
              }}
              slotProps={{
                textField: {
                  sx: {
                    "& fieldset": { borderRadius: "14px" },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </FormContainer>
      <Box sx={{ my: 5 }}>
        {isLoadingFile || formData[sectionMeter]?.certificate_path ? (
          <CardPdf
            fileInfo={fileInfo as FileBlobType}
            progress={progress}
            isSuccess={isSuccess}
            onClearFile={onClearFile}
          />
        ) : (
          <DragZone onDrop={onDrop as FileBlobType} typeFile="pdf" />
        )}
      </Box>
    </Box>
  );
};

export default MeterItem;

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;

type GridProps = {
  grid_columns: number;
};

const FormContainer = styled("form")(({ grid_columns }: GridProps) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
