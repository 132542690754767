import {
  FormControl,
  TextField,
  styled,
  Box,
  DragZone,
  CardPdf,
  Button,
  DeleteIcon,
  Divider,
  MenuItem,
  Select,
  // @ts-ignore
} from "@enerbit/base";
import { useCallback, useEffect, useState } from "react";
import { FileBlobType } from "../../models/base";
import {
  IArrayTechnicalSheet,
  ITechnicalSheet,
} from "../../models/TechnicalSheet";
import { onSelectDocument } from "../../services/uploadFile";

interface Props {
  p: ITechnicalSheet;
  handleChange: Function;
  body: IArrayTechnicalSheet;
  index: number;
  setFormData: Function;
  isWire?: boolean;
}

const SheetItem = ({
  p,
  handleChange,
  body,
  index,
  setFormData,
  isWire,
}: Props) => {
  const [fileInfo, setFileInfo] = useState<Blob[] | Blob>([]);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string>();

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    uploadFile(acceptedFiles[0]);
    setFileInfo(acceptedFiles[0]);
  }, []);

  const onClearFile = (): void => {
    setIsSuccess(false);
    setFilePath("");
    setProgress(0);
  };

  const uploadFile = async (file: Blob | string): Promise<void> => {
    setIsLoadingFile(true);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess);
      setFilePath(res);
      // updateStateFile(index, res);
    } catch (err) {
      // onClearFile();
    } finally {
      setIsLoadingFile(false);
    }
  };

  const deleteItem = (index: number): void => {
    setFormData(body.filter((_, i) => index !== i));
  };

  const wireList = [
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
  ];

  useEffect(() => {
    setFormData(
      body.map((item, i) =>
        index === i
          ? {
              ...item,
              form_data: {
                ...item["form_data"],
                certificate_path: filePath,
              },
            }
          : item
      )
    );
  }, [filePath]);

  return (
    <div>
      <Divider sx={{ my: 3 }} />
      {body.length > 1 && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={() => deleteItem(index)}
          >
            <DeleteIcon />
          </Button>
        </Box>
      )}
      <FormContainer grid_columns={2}>
        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>{isWire ? "Calibre" : "Marca"}</CustomLabel>
          {isWire ? (
            <Select
              name="gauge"
              value={p.form_data.gauge}
              sx={{ "& fieldset": { borderRadius: "12px" } }}
              onChange={handleChange(index)}
            >
              {wireList.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              sx={{ "& fieldset": { borderRadius: "12px" } }}
              label="marca"
              name="brand"
              value={p.form_data.brand}
              onChange={handleChange(index)}
            />
          )}
        </FormControl>

        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>{isWire ? "Material" : "modelo"}</CustomLabel>
          <TextField
            sx={{ "& fieldset": { borderRadius: "12px" } }}
            label={isWire ? "material" : "modelo"}
            name={isWire ? "material" : "model"}
            value={isWire ? p.form_data.material : p.form_data.model}
            onChange={handleChange(index)}
          />
        </FormControl>
      </FormContainer>
      <Box sx={{ my: 5 }}>
        {isLoadingFile || (p.form_data.certificate_path && filePath) ? (
          <CardPdf
            fileInfo={fileInfo as FileBlobType}
            progress={progress}
            isSuccess={isSuccess}
            onClearFile={onClearFile}
          />
        ) : (
          <DragZone onDrop={onDrop as FileBlobType} typeFile="pdf" />
        )}
      </Box>
    </div>
  );
};

export default SheetItem;

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;

type GridProps = {
  grid_columns: number;
};

const FormContainer = styled("form")(({ grid_columns }: GridProps) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
