import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCustomers } from "./thunk";
import { ICustomerItem } from "../../../models/IRespCustomer";
import { ITableAddress } from "../../../models/ITableAddress";

const initialState: ITableAddress = {
  listCustomers: [],
  loading: false,
  page: 0,
  size: 5,
  rowCount: 1,
  selectedCustomers: [],
  error: false,
  open: false,
  openAdd: false,
  name: "",
  messageTable: "",
  filter: "",
};

const tableAddress = createSlice({
  name: "tableAddress",
  initialState,
  reducers: {
    setFilter: (state, { payload }: PayloadAction<string>) => {
      state.filter = payload;
    },
    resetList: (state) => {
      state.listCustomers = [];
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
    },
    handleCustomer(state, { payload }: PayloadAction<ICustomerItem>) {
      state.selectedCustomers = [payload];
    },
    resetSelectedCustomer(state) {
      state.selectedCustomers = [];
    },
    openModal: (state) => {
      state.open = true;
    },
    closeModal: (state) => {
      state.open = false;
      resetTableAddress();
    },
    openAddModal: (state) => {
      state.openAdd = true;
    },
    closeAddModal: (state) => {
      state.openAdd = false;
    },
    resetTableAddress: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        if (!state.openAdd) {
          state.open = true;
        }
        state.listCustomers = payload.items;
        state.rowCount = Math.ceil(payload.total / state.size);
      }
    });
    builder.addCase(getCustomers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.listCustomers = [];
      if (payload === 404) {
        state.messageTable =
          "Este cliente no existe o no cuenta con predios asociados.";
      } else {
        state.open = false;
        state.messageTable =
          "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
      }
    });
  },
});

export const {
  setPage,
  setPageSize,
  handleCustomer,
  resetTableAddress,
  openModal,
  closeModal,
  openAddModal,
  closeAddModal,
  setName,
  resetList,
  setFilter,
  resetSelectedCustomer,
} = tableAddress.actions;

export default tableAddress.reducer;
