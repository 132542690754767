import {
  Typography,
  MenuItem,
  Select,
  FormControl,
  styled,
  LoadingButton,
  CustomAlert,
  Box,
  SelectChangeEvent,
} from "@enerbit/base";
import { useState, useEffect } from "react";
import { sendForm } from "../../../../helpers/sendForm";
import { MySeverityType } from "../../../../models/base";
import { formRoutineTest } from "../../../../models/RoutineTest";

import TransfoComponent from "./TransfoComponent";

const initialForm = {
  tc: {
    serial_number: ["", ""],
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  tt: {
    serial_number: [""],
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
};

type IOperatorId = {
  operatorId: string;
};

const RoutineTest = ({ operatorId }: IOperatorId) => {
  const [transformSelected, setTransformSelected] = useState("TC");
  const [formData, setFormData] = useState<formRoutineTest>(initialForm);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
  const [textAlert, setTexAlert] = useState("");
  const [serialArray, setSerialArray] = useState([
    {
      value: "",
    },
  ]);
  const [serialArrayTT, setSerialArrayTT] = useState([
    {
      value: "",
    },
  ]);

  useEffect(() => {
    setFormData(initialForm);
    setSerialArray([
      {
        value: "",
      },
    ]);
    setSerialArrayTT([
      {
        value: "",
      },
    ]);
    if (transformSelected === "TC") {
      setFormData({ ...formData, tt: null, tc: initialForm.tc });
    }
    if (transformSelected === "TT") {
      setFormData({
        ...formData,
        tc: null,
        tt: initialForm.tt,
      });
    }
    if (transformSelected === "TC y TT") {
      setFormData(initialForm);
    }
  }, [transformSelected]);

  const closeAlert = () => {
    setTexAlert("");
    setSeverityAlert("");
  };

  const setArrayTransformers = (
    option: String,
    serialOption: { value: string }[]
  ) => {
    const serialValues = serialOption.map(
      (item: { value: string }) => item.value
    );
    const body = serialValues.map((serialValue) => ({
      form_data: {
        certificate_number:
          formData[option as keyof formRoutineTest]?.certificate_number,
        certificate_path:
          formData[option as keyof formRoutineTest]?.certificate_path,
        issuer_entity: formData[option as keyof formRoutineTest]?.issuer_entity,
        serial_number: serialValue,
      },
      created_by: operatorId,
      document_type_id: "7279d9dc-55f5-11ed-9564-5e70b15957e9",
    }));
    return body;
  };

  const checkTransformerOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (transformSelected === "TC") {
      let body = setArrayTransformers("tc", serialArray);
      handleSubmitForm("tc", body);
    }
    if (transformSelected === "TT") {
      let body = setArrayTransformers("tt", serialArray);
      handleSubmitForm("tt", body);
    }
    if (transformSelected === "TC y TT") {
      let bodyTc = setArrayTransformers("tc", serialArray);
      handleSubmitForm("tc", bodyTc);
      let bodyTt = setArrayTransformers("tt", serialArrayTT);
      handleSubmitForm("tt", bodyTt);
    }
  };

  const handleSubmitForm = async (optionTransformer: string, body: any) => {
    setIsLoadingForm(true);
    const url = `/inventory-documentation/${optionTransformer}-rountine-tests-certificate`;
    try {
      await sendForm(url, body);
      setSeverityAlert("success");
      setTexAlert("Documento guardado con éxito");
      setFormData(initialForm);
      setSerialArray([
        {
          value: "",
        },
      ]);
      setSerialArrayTT([
        {
          value: "",
        },
      ]);
      //  resetForm();
    } catch (err) {
      setSeverityAlert("error");
      setTexAlert("No se pudo guardar el documento");
      return err;
    } finally {
      setIsLoadingForm(false);
    }
  };

  return (
    <div>
      <Typography
        color="primary"
        sx={{
          fontWeight: "300",
        }}
        variant="h4"
      >
        Pruebas de rutina
      </Typography>
      <Typography
        color="primary"
        sx={{
          fontWeight: "500",
          my: "36px",
        }}
        variant="h5"
      >
        Certificado de prueba de rutina {transformSelected}
      </Typography>
      <FormControl fullWidth sx={{ mb: "20px" }}>
        <CustomLabel>Aplica para</CustomLabel>
        <Select
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ "& fieldset": { borderRadius: "12px" } }}
          onChange={({ target }: SelectChangeEvent) =>
            setTransformSelected(target.value)
          }
          value={transformSelected}
        >
          <MenuItem value="TC">TC</MenuItem>
          <MenuItem value="TT">TT</MenuItem>
          <MenuItem value="TC y TT">TC y TT</MenuItem>
        </Select>
      </FormControl>
      {transformSelected && (
        <Box component="form" onSubmit={checkTransformerOption}>
          <TransfoComponent
            transformValue={
              transformSelected !== "TC y TT" ? transformSelected : "TC y TT"
            }
            formData={formData}
            sectionTransformer={
              transformSelected === "TC" || transformSelected === "TC y TT"
                ? "tc"
                : "tt"
            }
            setFormData={setFormData}
            setSerialArray={setSerialArray}
            serialArray={serialArray}
          />
          <Box sx={{ my: 5 }}></Box>
          {transformSelected === "TC y TT" && (
            <TransfoComponent
              transformValue="TT"
              formData={formData}
              setFormData={setFormData}
              sectionTransformer="tt"
              setSerialArray={setSerialArrayTT}
              serialArray={serialArrayTT}
            />
          )}
          <LoadingButton
            className="grid_column_1_3"
            variant="contained"
            color="secondary"
            loading={isLoadingForm}
            fullWidth
            type="submit"
            disabled={
              (transformSelected === "TC" && !formData.tc?.certificate_path) ||
              (transformSelected === "TT" && !formData.tt?.certificate_path) ||
              (transformSelected === "TC y TT" &&
                !formData.tc?.certificate_path) ||
              (transformSelected === "TC y TT" &&
                !formData.tt?.certificate_path)
            }
          >
            Enviar
          </LoadingButton>
        </Box>
      )}
      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </div>
  );
};

export default RoutineTest;

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;
