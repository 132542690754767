// import React, { useCallback, useState } from "react";
import {
  Box,
  Typography,
  CustomAlert,
  Button,
  LoadingButton,
  SelectChangeEvent,
} from "@enerbit/base";
import { useState, useEffect } from "react";
import { sendForm } from "../../helpers/sendForm";
import { MySeverityType } from "../../models/base";
import {
  formDataCertificateTcTt,
  IArrayFormData,
} from "../../models/itemCertifcate";
import ItemCertificate from "./ItemCertificate";

interface IoperatorId {
  operatorId: string;
  url: string;
  nameTransformer: string;
  selector: any; //todo
}

const TcTtCertificates = ({
  operatorId,
  url,
  nameTransformer,
  selector,
}: any) => {
  const initialForm = [
    {
      form_data: {
        number: "",
        number_first_relation: "",
        number_second_relation: "",
        issue_date: "",
        issuer_entity: "",
        certificate_path: "",
        normativity: "ntc",
      },
      created_by: operatorId,
    },
  ];

  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [formData, setFormData] = useState<IArrayFormData>(initialForm);
  const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
  const [textAlert, setTexAlert] = useState("");

  useEffect(() => {
    setFormData(initialForm);
    closeAlert();
  }, [selector]);

  const addNewItem = () => {
    setFormData([
      ...formData,
      {
        form_data: {
          number: "",
          number_first_relation: "",
          number_second_relation: "",
          issue_date: null,
          issuer_entity: "",
          normativity: "ntc",
          certificate_path: "",
        },
        created_by: operatorId,
      },
    ]);
  };

  const handleSubmitForm = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoadingForm(true);
    try {
      await sendForm(url, formData);
      setSeverityAlert("success");
      setTexAlert("Documento guardado con éxito");
      setFormData(initialForm);
    } catch (err) {
      setSeverityAlert("error");
      setTexAlert("No se pudo guardar el documento");
      return err;
    } finally {
      setIsLoadingForm(false);
    }
  };

  const closeAlert = () => {
    setTexAlert("");
    setSeverityAlert("");
  };

  const updateState = (index: number) => (e: SelectChangeEvent) => {
    setFormData(
      formData.map((item: formDataCertificateTcTt, i) =>
        index === i
          ? {
              ...item,
              form_data: {
                ...item["form_data"],
                [e.target.name]: e.target.value,
              },
            }
          : item
      )
    );
  };

  return (
    <>
      <Typography
        color="primary"
        sx={{
          fontWeight: "300",
        }}
        variant="h4"
      >
        Certificado de calibración de {nameTransformer}
      </Typography>

      <Box component="form" onSubmit={handleSubmitForm}>
        {formData.map((field, index) => (
          <ItemCertificate
            key={index}
            nameTransformer={nameTransformer}
            formData={formData}
            field={field}
            setFormData={setFormData}
            index={index}
            updateState={updateState}
          />
        ))}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            sx={{ my: 3 }}
            onClick={addNewItem}
          >
            + Agregar item
          </Button>
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          loading={isLoadingForm}
        >
          Enviar{" "}
        </LoadingButton>
      </Box>

      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </>
  );
};

export default TcTtCertificates;
