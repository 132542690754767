/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import { api } from "@enerBit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IResponseCustomers } from "../../../models/IRespCustomer";

export const getCustomers = createAsyncThunk(
  "tableAddress/getCustomers",
  async (
    {
      email,
      page = 0,
      size = 5,
      name = "",
    }: {
      email: string;
      page?: number;
      size?: number;
      name?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const parsedEmail = encodeURIComponent(email);
      const { data } = await api.get<IResponseCustomers>(
        `/assignees/customers?email=${parsedEmail}&page=${page}&size=${size}${
          name ? `&names=${name}` : ""
        }`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.status);
    }
  }
);
