//@ts-ignore
import * as yup from "yup";
//Para limpiar el formulario al cambiar de tipo de adjunto
export const initialValues = {
  brand: "",
  model: "",
  issue_date: "",
  expiration_date: "",
  serialNumber: "",
  meterNumber: "",
  issuer_entity: "",
  certificate_number: "",

  caliber: "",
  material: "",
  certificate_path: "",

  tcNumber: "", //Numero de TC
  firstRelationshipNumber: "", //Numero de relacion 1
  secondRelationshipNumber: "", //Numero de relacion 2

  ttNumber: "", //Numero de TT
  routineTests: "", //Aplica para Prueba de rutina

  //10.7 Certificado de calibracion de medidores - Single
  importActiveNumber: "",
  importActiveFile: "",
  importReactiveNumber: "",
  importReactiveFile: "",
  exportActiveNumber: "",
  exportActiveFile: "",
  exportReactiveNumber: "",
  exportReactiveFile: "",
  calibrationDate: "",

  issueDateImportActive: "",
  calibrationDateImportActive: "",
  issueDateExportActive: "",
  calibrationDateExportActive: "",
  issueDateImportReactive: "",
  calibrationDateImportReactive: "",
  issueDateExportReactive: "",
  calibrationDateExportReactive: "",

  //10.7 Certificado de calibracion de medidores - Import Export
  issueDateImport: "",
  calibrationDateImport: "",
  importNumber: "",
  importFile: "",
  exportNumber: "",
  exportFile: "",
  issueDateExport: "",
  calibrationDateExport: "",

  //10.15 Copia documentación técnica equipos de medida
  meterBrand: "",
  meterModel: "",
  meterFile: "",
  tccBrand: "",
  tccModel: "",
  tccFile: "",
  ttBrand: "",
  ttModel: "",
  ttFile: "",
  testBlockBrand: "",
  testBlockModel: "",
  testBlockFile: "",
  cableBrand: "",
  cableModel: "",
  cableFile: "",
};

//-----------------SCHEMAS-----------------//
//10.2 Certificado de conformidad producto de medidor
export const formSchema_meterComplianceCertificate = {
  brand: "",
  model: "",
  issue_date: "",
  expiration_date: "",
  certificate_number: "",
  issuer_entity: "",
  certificate_path: "",
};

//10.3 Certificado de conformidad producto tcs-tts
export const formSchema_tc_tt_compliances = {
  brand: "",
  model: "",
  issue_date: "",
  expiration_date: "",
  certificate_number: "",
  issuer_entity: "",
  certificate_path: "",
};

//10.4 Certificado conformidad producto borneras
export const formSchema_bornersComplianceCertificate = {
  brand: "",
  model: "",
  issue_date: "",
  expiration_date: "",
  certificate_number: "",
  issuer_entity: "",
  certificate_path: "",
};

//10.5 Certificado de conformidad producto cable
export const formSchema_wiresCompliances = {
  brand: "",
  model: "",
  issue_date: "",
  expiration_date: "",
  certificate_number: "",
  issuer_entity: "",
  certificate_path: "",
};
//10.6 Certificado de conformidad producto celda
export const formSchema_cellsCompliances = {
  brand: "",
  model: "",
  issue_date: "",
  expiration_date: "",
  certificate_number: "",
  issuer_entity: "",
  certificate_path: "",
};
//10.7 Certificado de calibracion medidores
export const formSchema_meterCalibrations = {
  meterNumber: "",
  issuer_entity: "",
  groupedCertificates: "",
};
//10.7 Certificado de calibracion medidores Single
export const formSchema_meterCalibrations_single = {
  meterNumber: "",
  issuer_entity: "",

  importActiveNumber: "",
  issueDateImportActive: "",
  calibrationDateImportActive: "",
  importActivecertificateFile: "",

  // exportActiveNumber: "",
  // issueDateExportActive: "",
  // calibrationDateExportActive: "",
  // exportActiveFile: "",

  // importReactiveNumber: "",
  // issueDateImportReactive: "",
  // calibrationDateImportReactive: "",
  // importReactiveFile: "",

  // exportReactiveNumber: "",
  // issueDateExportReactive: "",
  // calibrationDateExportReactive: "",
  // exportReactiveFile: "",
};
//10.7 Certificado de calibracion medidores ImportExport
export const formSchema_meterCalibrations_importExport = {
  importNumber: "",
  issueDateImport: "",
  calibrationDateImport: "",
  importFile: "",
  exportNumber: "",
  issueDateExport: "",
  calibrationDateExport: "",
  exportFile: "",
};
//10.8.1 Certificado de calibracion tc
export const formSchema_tcCalibrations = {
  number: "",
  number_first_relation: "",
  number_second_relation: "",
  issue_date: "",
  issuer_entity: "",
  certificate_path: "",
};
//10.8.2 Certificado de calibración tt
export const formSchema_ttCalibrations = {
  number: "",
  number_first_relation: "",
  number_second_relation: "",
  issue_date: "",
  issuer_entity: "",
  certificate_path: "",
};
//10.9 Certificado de pruebas de rutina
export const formSchema_routineTests = {
  routineTests: "",
  serialNumber: "",
  certificate_number: "",
  issue_date: "",
  issuer_entity: "",
  certificate_path: "",
};
//10.10 Procedimiennto de mantenimiento medida
export const formSchema_maintenanceProcedureMeasurements = {
  certificate_path: "",
};
//10.15 Copia de documentacion tecnica equipos de medida
// export const formSchema_copyTechnicalDocumentationMeasurementEquipment = {
//   meterBrand: "",
//   meterModel: "",
//   meterFile: "",

//   tccBrand: "",
//   tccModel: "",
//   tccFile: "",

//   ttBrand: "",
//   ttModel: "",
//   ttFile: "",

//   testBlockBrand: "",
//   testBlockModel: "",
//   testBlockFile: "",

//   cableBrand: "",
//   cableModel: "",
//   cableFile: "",
// };

export const formSchema_copyTechnicalDocumentationMeter = {
  meterBrand: "",
  meterModel: "",
  meterFile: "",
};

export const formSchema_copyTechnicalDocumentationTc = {
  meterBrand: "",
  meterModel: "",
  meterFile: "",
};

export const formSchema_copyTechnicalDocumentationTt = {
  meterBrand: "",
  meterModel: "",
  meterFile: "",
};

export const formSchema_copyTechnicalDocumentationBlockTest = {
  meterBrand: "",
  meterModel: "",
  meterFile: "",
};

export const formSchema_copyTechnicalDocumentationBlockModem = {
  meterBrand: "",
  meterModel: "",
  meterFile: "",
};

export const formSchema_copyTechnicalDocumentationCable = {
  meterBrand: "",
  meterModel: "",
  meterFile: "",
};
//10.16 Parametros procedimientos politicas cgm
export const formSchema_parametersProceduresPoliciesCgm = {
  certificate_path: "",
};
//10.17 Esquema diagrama TLM y comunicacion medidores
export const formSchema_tlmDiagramMeterCommunication = {
  certificate_path: "",
};
//10.18 Condiciones op comunicaciones, medidores y cgm
export const formSchema_conditionsOpCommunicationsMetersCgm = {
  certificate_path: "",
};

export const formSchema_procedureCritiqueInformation = {
  certificate_path: "",
};

export const formSchema10_20 = {
  certificate_path: "",
};
//10.21 Mecanismo de protección, seguridad e integridad
export const formSchema_physicalComputerSecurityDataProtection = {
  certificate_path: "",
};

export const formSchema10_22 = {
  certificate_path: "",
};

export const formSchema_cgmAsicDataTransmissionProcedure = {
  certificate_path: "",
};

export const formSchema_lastReadLogFileSentCgmAsic = {
  certificate_path: "",
};

export const formSchema_cgmAnnualOperatingReport = {
  certificate_path: "",
};

export const formSchema10_26 = {
  certificate_path: "",
};

export const formSchema10_27 = {
  certificate_path: "",
};

export const formSchema10_28 = {
  certificate_path: "",
};

export const formSchemMaintenance = {
  certificate_path: "",
};

export const formSchema10_31 = {
  certificate_path: "",
};

// VALIDATIONS_SCHEMAS
//10.2 Certificado de calibración de medidores
export const validationSchema_meterComplianceCertificate = yup.object({
  brand: yup.string().required("La marca es requerida"),
  model: yup.string().required("El modelo es requerido"),
  issue_date: yup.string().required("La fecha de emisión es requerida"),
  expiration_date: yup
    .string()
    .required("La fecha de vencimiento es requerida"),
  certificate_number: yup
    .string()
    .required("El número de certificado es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});

export const validationSchema_tc_tt_compliances = yup.object({
  brand: yup.string().required("La marca es requerida"),
  model: yup.string().required("El modelo es requerido"),
  issue_date: yup.date().required("La fecha de emisión es requerida"),
  expiration_date: yup.date().required("La fecha de vencimiento es requerida"),
  certificate_number: yup
    .string()
    .required("El número de certificado es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});

//10.4 Certificado de calibracion borneras
export const validationSchema_bornersComplianceCertificate = yup.object({
  brand: yup.string().required("La marca es requerida"),
  model: yup.string().required("El modelo es requerido"),
  issue_date: yup.date().required("La fecha de emisión es requerida"),
  expiration_date: yup.date().required("La fecha de vencimiento es requerida"),
  certificate_number: yup
    .string()
    .required("El número de certificado es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});
//10.5 Certificado de conformidad producto cable
export const validationSchema_wiresCompliances = yup.object({
  brand: yup.string().required("La marca es requerida"),
  gauge: yup.string().required("El calibre es requerido"),
  material: yup.string().required("El material es requerido"),
  issue_date: yup.date().required("La fecha de emisión es requerida"),
  expiration_date: yup.date().required("La fecha de vencimiento es requerido"),
  certificate_number: yup
    .string()
    .required("El número de certificado es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});
//10.6 Certificado conformidad producto celda
export const validationSchema_cellsCompliances = yup.object({
  brand: yup.string().required("La marca es requerida"),
  model: yup.string(),
  issue_date: yup.date().required("La fecha de emisión es requerida"),
  expiration_date: yup.date().required("La fecha de vencimiento es requerida"),
  certificate_number: yup
    .string()
    .required("El número de certificado es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});
//10.7 Certificado de calibracion de medidores
export const validationSchema_meterCalibrations = yup.object({
  meterNumber: yup.string().required("El número de medidor es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
  groupedCertificates: yup
    .string()
    .required("El número de certificados agrupados es requerido"),
});
//10.7 Certificado de calibracion de medidores agrupados por certificado individual
export const validationSchema_meterCalibrations_single = yup.object({
  meterNumber: yup.string().required("El número de medidor es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),

  importActiveNumber: yup
    .string()
    .required("El número de importación activa es requerido"),
  issueDateImportActive: yup
    .date()
    .required("La fecha de emisión de importación activa es requerida"),
  calibrationDateImportActive: yup
    .date()
    .required("La fecha de calibración de importación activa es requerida"),

  exportActiveNumber: yup
    .string()
    .required("El número de exportación activa es requerido"),
  issueDateExportActive: yup
    .date()
    .required("La fecha de emisión de exportación activa es requerida"),
  calibrationDateExportActive: yup
    .date()
    .required("La fecha de calibración de exportación activa es requerida"),

  importReactiveNumber: yup
    .string()
    .required("El número de importación reactiva es requerido"),
  issueDateImportReactive: yup
    .date()
    .required("La fecha de emisión de importación reactiva es requerida"),
  calibrationDateImportReactive: yup
    .date()
    .required("La fecha de calibración de importación reactiva es requerida"),

  exportReactiveNumber: yup
    .string()
    .required("El número de exportación reactiva es requerido"),
  issueDateExportReactive: yup
    .date()
    .required("La fecha de emisión de exportación reactiva es requerida"),
  calibrationDateExportReactive: yup
    .date()
    .required("La fecha de calibración de exportación reactiva es requerida"),
});
//10.7 Certificado de calibracion de medidores agrupados por Importada - Exportada
export const validationSchema_meterCalibrations_importExport = yup.object({
  meterNumber: yup.string().required("El número de medidor es requerido"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),

  importNumber: yup
    .string()
    .required("El número de certificado importada es requerido"),
  issueDateImport: yup
    .date()
    .required("La fecha de emisión de certificado importada es requerida"),
  calibrationDateImport: yup
    .date()
    .required("La fecha de calibración de certificado importada es requerida"),

  exportNumber: yup
    .string()
    .required("El número de certificado exportada es requerido"),
  issueDateExport: yup
    .date()
    .required("La fecha de emisión de certificado exportada es requerida"),
  calibrationDateExport: yup
    .date()
    .required("La fecha de calibración de certificado exportada es requerida"),

  //file: yup.string().required("El archivo es requerido"),
});
//10.8.1 Certificado de calibración TC
export const validationSchema_tcCalibrations = yup.object({
  number: yup.string().required("El número de TC es requerido"),
  number_first_relation: yup
    .string()
    .required("El número del certificado primera relación es requerido"),
  number_second_relation: yup
    .string()
    .required("El número del certificado segunda relación es requerido"),
  issue_date: yup.string().required("La fecha de emisión es requerida"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});
//10.8.2 Certificado de calibración TT
export const validationSchema_ttCalibrations = yup.object({
  number: yup.string().required("El número de TT es requerido"),
  number_first_relation: yup
    .string()
    .required("El número del certificado primera relación es requerido"),
  number_second_relation: yup
    .string()
    .required("El número del certificado segunda relación es requerido"),
  issue_date: yup.date().required("La fecha de emisión es requerida"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});
//10.9 Certificado de pruebas de rutina
export const validationSchema_routineTests = yup.object({
  routineTests: yup
    .string()
    .required("El tipo de prueba de rutina es requerido"),
  serialNumber: yup.string().required("El número de serie es requerido"),
  certificate_number: yup
    .string()
    .required("El número de certificado es requerido"),
  issue_date: yup.date().required("La fecha de emisión es requerida"),
  issuer_entity: yup.string().required("El ente emisor es requerido"),
});
//10.10 Procedimiennto de mantenimiento medida
export const validationSchema_maintenanceProcedureMeasurements = yup.object({});

//10.15 Copia de documentacion tecnica equipos de medida
export const validationSchema_copyTechnicalDocumentationMeasurementEquipment =
  yup.object({
    meterBrand: yup.string().required("La marca es requerida"),
    meterModel: yup.string().required("El modelo es requerido"),
    //meterFile: yup.string().required("El archivo es requerido"),
    tccBrand: yup.string().required("La marca es requerida"),
    tccModel: yup.string().required("El modelo es requerido"),
    //tccFile: yup.string().required("El archivo es requerido"),
    ttBrand: yup.string().required("La marca es requerida"),
    ttModel: yup.string().required("El modelo es requerido"),
    //ttFile: yup.string().required("El archivo es requerido"),
    testBlockBrand: yup.string().required("La marca es requerida"),
    testBlockModel: yup.string().required("El modelo es requerido"),
    //testBlockFile: yup.string().required("El archivo es requerido"),
    cableBrand: yup.string().required("La marca es requerida"),
    cableModel: yup.string().required("El modelo es requerido"),
    //cableFile: yup.string().required("El archivo es requerido"),
  });
//10.16 Parametros procedimientos politicas cgm
export const validationSchema_parametersProceduresPoliciesCgm = yup.object({});
//10.17 Esquema diagrama TLM y comunicacion medidores
export const validationSchema_tlmDiagramMeterCommunication = yup.object({});
//10.18 Condiciones op comunicaciones, medidores y cgm
export const validationSchema_conditionsOpCommunicationsMetersCgm = yup.object(
  {}
);
//10.19 Procedimiento para la critica de informacion
export const validationSchema_procedureCritiqueInformation = yup.object({});
//falta
export const validationSchema10_20 = yup.object({});

export const validationSchema10_21 = yup.object({});
//10.22 P Seguridad fisica e informatica para protección de datos
export const validationSchema_physicalComputerSecurityDataProtection =
  yup.object({});

export const validationSchema_cgmAsicDataTransmissionProcedure = yup.object({});
//10.24 Ultimo achivo registro lectura enviado cgm al asic
export const validationSchema_lastReadLogFileSentCgmAsic = yup.object({});

export const validationSchema_cgmAnnualOperatingReport = yup.object({});

export const validationSchema10_26 = yup.object({});

export const validationSchema10_27 = yup.object({});

export const validationSchema10_28 = yup.object({});

export const validationSchema10_31 = yup.object({});
