import {
  DialogContent,
  Grid,
  Button,
  Dialog,
  DialogTitle,
} from "@enerbit/base";
import { useAppDispatch, useAppSelector } from "../../store/libraryStore";
import { closeModal, resetSelectedCustomer } from "../../store/slices/table-address/tableAddress";
import { AddressTableClient } from "./AddressTableClient";
import { StyledDialog, StyledDialogTitle } from "./StyledDialog";

export const CustomerModal = () => {
  const { loading, open, selectedCustomers } = useAppSelector(
    (state) => state.table
  );

  const dispatch = useAppDispatch();

  const handleAdd = () => {
    dispatch(closeModal());
  };

  const handleClose = () => {
    dispatch(closeModal());
    dispatch(resetSelectedCustomer())
  };

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Predios
      </StyledDialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AddressTableClient />
          </Grid>
          <Grid item xs={12}>
            <Button
              id={"table-button"}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleAdd}
            >
              Ver
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
