import React from "react";
import {
  Box,
  Menu,
  MenuItem,
  MoreVertIcon,
  RemoveRedEyeOutlinedIcon,
  styled,
  Typography,
} from "@enerbit/base";
// import { libraryOptions } from "../../../const/libraryJson";
// import download from "downloadjs";
// import FileSaver from "file-saver";

interface Props {
  file: string;
  option: string;
  date: string;
  fileName?: string;
}

const OptionsMenu = ({ file, option, date, fileName }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function for download files
  // const downloadPdf = () => {
  //   const name = `${fileName ?? libraryOptions[option].fileName}_${date}.pdf`;
  //   fetch(file)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       FileSaver.saveAs(blob, name);
  //     });
  // };

  return (
    <div>
      <Box sx={{ cursor: "pointer" }} id='basic-button' onClick={handleClick}>
        <MoreVertIcon />
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "190px" } }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        {/* <MenuItem
          sx={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            downloadPdf();
            handleClose();
          }}>
          <ItemContainer>
            <DownloadOutlinedIcon />
            <Typography
              sx={{
                fontSize: "14px",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}>
              Descargar
            </Typography>
          </ItemContainer>
        </MenuItem> */}
        <DownloadLink href={file} target='_blank'>
          <MenuItem sx={{ display: "flex", alignItems: "center" }} onClick={handleClose}>
            <ItemContainer>
              <RemoveRedEyeOutlinedIcon />
              <Typography
                sx={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}>
                Previsualizar
              </Typography>
            </ItemContainer>
          </MenuItem>
        </DownloadLink>
      </Menu>
    </div>
  );
};

export default OptionsMenu;

const ItemContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const DownloadLink = styled("a")`
  text-decoration: none;
  color: #000000;
`;

const MenuContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center:
`;
