import { yup } from "@enerbit/base";

export const ModelAndBrandValidation = yup.object({
  brand: yup.string().optional(),
  model: yup.string().optional(),
  gauge: yup.string().optional(),
  material: yup.string().optional(),
});

export const SerialValidation = yup.object({
  serial: yup.string().optional(),
});

export const TechnicalEquipmentValidation = yup.object({
  brand: yup.string().optional(),
  model: yup.string().optional(),
  gauge: yup.string().optional(),
  material: yup.string().optional(),
  equipment: yup.string().optional(),
});
