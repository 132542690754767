import {
  Box,
  Button,
  LoadingButton,
  CustomAlert,
  Typography,
  SelectChangeEvent,
  // @ts-ignore
} from "@enerbit/base";
import SheetItem from "./SheetItem";
import { useState, useEffect } from "react";
import { sendForm } from "../../helpers/sendForm";
import { IArrayTechnicalSheet } from "../../models/TechnicalSheet";
import { MySeverityType } from "../../models/base";

type Props = {
  operatorId: string;
  url: string;
  selector: string;
  label: string;
  isWire?: boolean;
};

const TechnicalSheet = ({
  operatorId,
  url,
  selector,
  label,
  isWire,
}: Props) => {
  const initialForm = isWire
    ? [
        {
          form_data: {
            gauge: "",
            material: "",
            certificate_path: "",
          },
          created_by: operatorId,
        },
      ]
    : [
        {
          form_data: {
            model: "",
            brand: "",
            certificate_path: "",
          },
          created_by: operatorId,
        },
      ];

  useEffect(() => {
    setFormData(initialForm);
    closeAlert();
  }, [selector]);

  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
  const [textAlert, setTextAlert] = useState("");
  const [formData, setFormData] = useState<IArrayTechnicalSheet>(initialForm);

  const closeAlert = () => {
    setTextAlert("");
    setSeverityAlert("");
  };

  const handleSubmitForm = async (
    e: React.FormEvent<HTMLFormElement>,
    url: string = "",
    body: IArrayTechnicalSheet
  ): Promise<void> => {
    e.preventDefault();
    setIsLoadingForm(true);
    try {
      await sendForm(url, body);
      setSeverityAlert("success");
      setTextAlert("Documento guardado con éxito");
      setFormData(initialForm);
    } catch (err) {
      setSeverityAlert("error");
      setTextAlert("No se pudo guardar el documento");
    } finally {
      setIsLoadingForm(false);
    }
  };

  const addNewItem = () => {
    setFormData(
      isWire
        ? [
            ...formData,
            {
              // id: Math.random(),
              form_data: {
                gauge: "",
                material: "",
                certificate_path: "",
              },
              created_by: operatorId,
            },
          ]
        : [
            ...formData,
            {
              // id: Math.random(),
              form_data: {
                model: "",
                brand: "",
                certificate_path: "",
              },
              created_by: operatorId,
            },
          ]
    );
  };

  const updateState = (index: number) => (e: SelectChangeEvent) => {
    setFormData(
      formData.map((item, i) =>
        index === i
          ? {
              ...item,
              form_data: {
                ...item["form_data"],
                [e.target.name]: e.target.value,
              },
            }
          : item
      )
    );
  };

  return (
    <Box>
      <Typography
        color="primary"
        sx={{
          fontWeight: "300",
          my: "36px",
        }}
        variant={"h5"}
      >
        {label}
      </Typography>
      <form onSubmit={(e) => handleSubmitForm(e, url, formData)}>
        <div>
          {formData.map((field, index) => {
            return (
              <div key={index}>
                <SheetItem
                  p={field}
                  handleChange={updateState}
                  index={index}
                  body={formData}
                  setFormData={setFormData}
                  isWire={isWire}
                />
              </div>
            );
          })}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              sx={{ my: 3 }}
              onClick={addNewItem}
            >
              + Agregar item
            </Button>
          </Box>
        </div>
        {/* )} */}
        {/* </FieldArray> */}

        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          loading={isLoadingForm}
        >
          Enviar{" "}
        </LoadingButton>
      </form>

      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </Box>
  );
};

export default TechnicalSheet;
