export const AttachmentTypeMenuItems = [
  {
    value: "meter_compliance_certificate",
    text: "Certificado conformidad producto de medidor",
  },
  {
    value: "tc_compliances",
    text: "Certificado conformidad producto TCS",
  },
  {
    value: "tt_compliances",
    text: "Certificado conformidad producto TTS",
  },
  {
    value: "borners_compliance_certificate",
    text: "Certificado conformidad producto borneras",
  },
  {
    value: "wires_compliances",
    text: "Certificado conformidad producto cable",
  },
  {
    value: "cells_compliances",
    text: "Certificado conformidad producto celda",
  },
  {
    value: "meter_calibrations",
    text: "Certificado de calibración de medidores",
  },
  {
    value: "tc_calibrations",
    text: "Certificado de calibración TC",
  },
  {
    value: "tt_calibrations",
    text: "Certificado de calibración TT",
  },
  { value: "routine_test", text: "Pruebas de rutina" },
  {
    value: "copy_technical_documentation_measurement_equipment_meter",
    text: "Ficha técnica Medidor",
  },
  {
    value: "copy_technical_documentation_measurement_equipment_tc",
    text: "Ficha técnica TC",
  },
  {
    value: "copy_technical_documentation_measurement_equipment_tt",
    text: "Ficha técnica TT",
  },
  {
    value: "copy_technical_documentation_measurement_block_test",
    text: "Ficha técnica bloque de prueba",
  },
  {
    value: "copy_technical_documentation_measurement_modem",
    text: "Ficha técnica módem",
  },
  {
    value: "copy_technical_documentation_measurement_equipment_cable",
    text: "Ficha  técnica Cable",
  },

  {
    value: "parameters_procedures_policies_cgm",
    text: "Parámetros procedimientos y políticas CGM",
  },
  {
    value: "tlm_diagram_meter_communication",
    text: "Esquema diagrama TLM y comunicación medidores",
  },
  {
    value: "conditions_op_communications_meters_cgm",
    text: "Condiciones op comunicaciones, medidores y cgm",
  },
  {
    value: "information_criticism_procedure",
    text: "Procedimiento para la crítica de información",
  },
  {
    value: "procedure_val_data_read_meters",
    text: "Procedimiento validación de datos Leidos de los medidores",
  },
  {
    value: "physical_computer_security_data_protection",
    text: "Mecanismo de protección, seguridad e integridad",
  },
  {
    value: "physical_and_information_security_protection_data",
    text: "Procedimiento seguridad física e informática para protección datos",
  },
  {
    value: "cgm_asic_data_transmission_procedure",
    text: "Procedimiento transmisión datos CGM-ASIC",
  },
  {
    value: "maintenance_procedure_measurements",
    text: "Procedimiento de mantenimiento de la medida",
  },

  {
    value: "annual_operation_report_cgm",
    text: "Informe anual operación CGM",
  },
  {
    value: "record_dosser_previous_supplier",
    text: "Hoja de vida comercializador previo",
  },

  // {
  //   value: "meterTemplate",
  //   text: "Creación masiva de plantilla de medidores",
  // },
  // {
  //   value: "meters_batch",
  //   text: "Creación masiva de medidores",
  // },
  {
    value: "massive_meters_calibration",
    text: "Creación masiva de calibración de medidores",
  },
];
