// @ts-nocheck
import {
  FormControl,
  Select,
  MenuItem,
  styled,
  decodedJwt,
  CustomAlert,
  Box,
  useFormik,
  Typography,
  TextField,
  LocalizationProvider,
  AdapterDayjs,
  DatePicker,
  CardPdf,
  DragZone,
  FormHelperText,
  LoadingButton,
  dayjs,
  // @ts-ignore
} from "@enerbit/base";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

//@ts-ignore

import {
  initialValues,
  validationSchema_meterCalibrations_single, //10.7 Certificado de calibracion medidores Single
  validationSchema_meterCalibrations_importExport,
  formSchema_meterCalibrations_single,
  formSchema_meterCalibrations_importExport, //10.7 Certificado de calibracion medidores
} from "./components/schema-validation/validations";
import { attachmentTypeInputs } from "./components/attatchment-type-menu-items/AttachmentTypeInputs";
import { AttachmentTypeMenuItems } from "./components/attatchment-type-menu-items/AttatchmentTypeMenuItems";
import { onSelectDocument } from "../../services/uploadFile";
import { sendForm } from "../../helpers/sendForm";
// import MeterBatch from "./components/meters-batch/MeterBatch";
import MassiveMeters from "./components/massive_meters_calibrations/MassiveMeters";
import { IFormValue } from "../../models/FormValues";
import { DetermineFormComponent } from "./DetermineFormComponent";
import { FieldSchemas, OptionsSelect } from "../../models/FormSchema";
// import MeterTemplate from "./components/meter-template/MeterTemplate";

const MeasurementPointPage = () => {
  //Selector de tipo de documento, que guardará el valor del select del tipo de adjunto
  const [formValues, setFormValues] = useState<IFormValue>({});
  const [attachmentTypeSelector, setAttachmentTypeSelector] = useState("");
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileInfo, setFileInfo] = useState<Blob>();
  const [isLoadingForm, setIsLoadingSendForm] = useState(false);
  const [operatorId, setOperatorId] = useState("");
  const [severityAlert, setSeverityAlert] = useState("");
  const [textAlert, setTexAlert] = useState("");
  const [filePath, setFilePath] = useState<Blob | string>();
  //Metodo para cambiar el valor del selector de tipo de adjunto

  const handleChangeSelectAttachmentType = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setAttachmentTypeSelector(e.target.value);
    setFormValues(initialValues);
  };

  useEffect(() => {
    setFormValues({
      ...formValues,
      certificate_path: filePath,
    });
  }, [filePath]);

  const onClearFile = (): void => {
    setIsSuccess(false);
    setFormValues({ ...formValues, certificate_path: "" });
    setProgress(0);
  };

  //Estado para guardar los valores del formulario

  useEffect(() => {
    const { sub } = decodedJwt() as any;
    setOperatorId(sub);
    setSeverityAlert("");
    setTexAlert("");
    if (formValues.groupedCertificates === "individual_single_certificate") {
      setFormValues(formSchema_meterCalibrations_single);
    } else if (formValues.groupedCertificates === "import_export_certificate") {
      setFormValues(formSchema_meterCalibrations_importExport);
    } else {
      setFormValues(attachmentTypeInputs[attachmentTypeSelector]?.formSchema);
    }
  }, [attachmentTypeSelector]);

  const handleSubmitForm = async () => {
    setIsLoadingSendForm(true);

    // const endPoint: any = attachmentTypeInputs[attatchmentTypeSelector].endPoint;

    const url = attachmentTypeInputs[attachmentTypeSelector].endPoint;
    const body = {
      form_data: formValues,
      created_by: operatorId,
      document_type_id: "7279d9dc-55f5-11ed-9564-5e70b15957e9",
    };
    try {
      await sendForm(url, body);
      setSeverityAlert("success");
      setTexAlert("Documento guardado con éxito");
      setFormValues(attachmentTypeInputs[attachmentTypeSelector]?.formSchema);
      //  resetForm();
    } catch (err) {
      setSeverityAlert("error");
      setTexAlert("No se pudo guardar el documento");
      return err;
    } finally {
      setIsLoadingSendForm(false);
    }
  };

  const closeAlert = () => {
    setTexAlert("");
    setSeverityAlert("");
  };

  //Para validar formulario
  const formik = useFormik({
    initialValues: formValues, //formik.values
    validationSchema:
      attachmentTypeSelector === "meter_calibrations" &&
      formValues.groupedCertificates === "individual_single_certificate"
        ? validationSchema_meterCalibrations_single
        : attachmentTypeSelector === "meter_calibrations" &&
          formValues.groupedCertificates === "import_export_certificate"
        ? validationSchema_meterCalibrations_importExport
        : attachmentTypeInputs[attachmentTypeSelector]?.validationSchema,

    onSubmit: () => {
      handleSubmitForm();

      formik.resetForm();
    },
  });

  const uploadFile = async (file: Blob): Promise<void> => {
    setIsLoadingFile(true);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess);
      setFilePath(res);
    } catch (err) {
      // onClearFile();
    } finally {
      setIsLoadingFile(false);
    }
  };

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    setFileInfo(acceptedFiles[0]);
    uploadFile(acceptedFiles[0]);
  }, []);

  return (
    <>
      <FormControl sx={{ width: "100%", mb: "20px" }}>
        <CustomLabel>¿Qué documento deseas adjuntar?</CustomLabel>
        <Select
          sx={{ "& fieldset": { borderRadius: "12px" } }}
          inputProps={{ "aria-label": "Without label" }}
          onChange={handleChangeSelectAttachmentType}
          value={attachmentTypeSelector}
        >
          {AttachmentTypeMenuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {attachmentTypeSelector !== "record_dosser_previous_supplier" &&
      attachmentTypeSelector !== "routine_test" &&
      attachmentTypeSelector !== "meter_calibrations" &&
      attachmentTypeSelector !== "massive_meters_calibration" &&
      attachmentTypeSelector !==
        "copy_technical_documentation_measurement_equipment_meter" &&
      attachmentTypeSelector !==
        "copy_technical_documentation_measurement_equipment_tc" &&
      attachmentTypeSelector !==
        "copy_technical_documentation_measurement_equipment_tt" &&
      attachmentTypeSelector !==
        "copy_technical_documentation_measurement_block_test" &&
      attachmentTypeSelector !==
        "copy_technical_documentation_measurement_modem" &&
      attachmentTypeSelector !==
        "copy_technical_documentation_measurement_equipment_cable" &&
      attachmentTypeSelector !== "tc_calibrations" &&
      attachmentTypeSelector !== "tt_calibrations" &&
      attachmentTypeSelector !== "meterTemplate" &&
      attachmentTypeSelector !== "meters_batch" ? (
        <FormContainer grid_columns={2} onSubmit={formik.handleSubmit}>
          {attachmentTypeSelector &&
            attachmentTypeInputs[attachmentTypeSelector]?.fields.map(
              (item: FieldSchemas, index: number) => (
                <FormControl
                  key={index}
                  sx={{ mb: "20px", flexDirection: "column" }}
                  fullWidth
                  className={
                    item.grid === "1_2"
                      ? "grid_column_1_2"
                      : item.grid === "2_3"
                      ? "grid_column_2_3"
                      : "grid_column_1_3"
                  }
                >
                  {item.type === "title" || item.type === "subtitle" ? (
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: item.type === "title" ? "300" : "500",
                        mt: item.type === "subtitle" && "36px",
                      }}
                      variant={item.type === "title" ? "h4" : "h5"}
                    >
                      {item.label}
                    </Typography>
                  ) : (
                    <CustomLabel>{item.label}</CustomLabel>
                  )}
                  {item.type === "text" ? (
                    <>
                      <TextField
                        placeholder={item.label}
                        fullWidth
                        type="text"
                        name={item.nameValue}
                        sx={{ "& fieldset": { borderRadius: "12px" } }}
                        value={
                          (formik.values[item.nameValue] =
                            formValues[item.nameValue])
                        }
                        error={
                          formik.touched[item.nameValue] &&
                          Boolean(formik.errors[item.nameValue])
                        }
                        helperText={
                          formik.touched[item.nameValue] &&
                          formik.errors[item.nameValue]
                        }
                        onChange={({
                          target,
                        }: React.ChangeEvent<HTMLInputElement>) =>
                          setFormValues({
                            ...formValues,
                            [item.nameValue]: target.value,
                          })
                        }
                      />
                    </>
                  ) : item.type === "select" ? (
                    <>
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        defaultValue=""
                        sx={{ "& fieldset": { borderRadius: "12px" } }}
                        onChange={({
                          target,
                        }: React.ChangeEvent<HTMLInputElement>) =>
                          setFormValues({
                            ...formValues,
                            [item.nameValue]: target.value,
                          })
                        }
                        value={
                          (formik.values[item.nameValue] =
                            formValues[item.nameValue])
                        }
                        // required
                        name={item.nameValue}
                        error={
                          formik.touched[item.nameValue] &&
                          Boolean(formik.errors[item.nameValue])
                        }
                        helperText={
                          formik.touched[item.nameValue] &&
                          formik.errors[item.nameValue]
                        }
                      >
                        {/* Recorrer dinamicamente las opciones del select */}
                        {item?.selectOptions?.map(
                          (option: OptionsSelect, index: number) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText error>
                        {formik.touched[item.nameValue] &&
                          formik.errors[item.nameValue]}
                      </FormHelperText>
                    </>
                  ) : item.type === "date" ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          maxDate={item.isIssueDate ? dayjs() : null}
                          minDate={item.isExpirationDate ? dayjs() : null}
                          label={item.label}
                          value={
                            (formik.values[item?.nameValue] =
                              dayjs(formValues[item?.nameValue]) || null)
                          }
                          format="YYYY/MM/DD"
                          onChange={(newValue: Date | any) => {
                            setFormValues({
                              ...formValues,
                              [item.nameValue]: newValue?.format("YYYY-MM-DD"),
                            });
                          }}
                          renderInput={(params: {
                            name: string;
                            value: string;
                          }) => (
                            <TextField
                              error={
                                formik.touched[item.nameValue] &&
                                Boolean(formik.errors[item.nameValue])
                              }
                              helperText={
                                !!formik.touched[item.nameValue] &&
                                formik.errors[item.nameValue]
                              }
                              {...params}
                              fullWidth
                              sx={{ "& fieldset": { borderRadius: "12px" } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  ) : item.type === "dragAndDrop" ? (
                    <>
                      {isLoadingFile || formValues.certificate_path ? (
                        <CardPdf
                          fileInfo={fileInfo as any}
                          progress={progress}
                          isSuccess={isSuccess}
                          onClearFile={onClearFile}
                          typeFile={item.typeFile}
                        />
                      ) : (
                        <DragZone onDrop={onDrop} typeFile="pdf" />
                      )}
                    </>
                  ) : null}
                </FormControl>
              )
            )}
          {attachmentTypeSelector && (
            <LoadingButton
              className="grid_column_1_3"
              variant="contained"
              color="secondary"
              loading={isLoadingForm}
              fullWidth
              type="submit"
              disabled={!formValues?.certificate_path}
            >
              Enviar
            </LoadingButton>
          )}
        </FormContainer>
      ) : attachmentTypeSelector === "massive_meters_calibration" ? (
        <MassiveMeters operatorId={operatorId} />
      ) : (
        <DetermineFormComponent
          attachmentTypeSelector={attachmentTypeSelector}
          operatorId={operatorId}
        />
      )}
      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert as any}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </>
  );
};

export default MeasurementPointPage;

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;

type GridProps = {
  grid_columns: number;
};

const FormContainer = styled("form")(({ grid_columns }: GridProps) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
