import {
  FormControl,
  TextField,
  Typography,
  styled,
  Box,
  DragZone,
  CardPdf,
  Button,
  DeleteIcon,
} from "@enerbit/base";
import { useCallback, useState, useEffect, ChangeEvent } from "react";
import { FileBlobType } from "../../../../models/base";
import { formRoutineTest } from "../../../../models/RoutineTest";
import { onSelectDocument } from "../../../../services/uploadFile";

interface Props {
  transformValue: string;
  formData: formRoutineTest;
  setFormData: Function;
  sectionTransformer: string;
  setSerialArray: Function;
  serialArray: { value: string }[];
}

const TransfoComponent = ({
  transformValue,
  formData,
  setFormData,
  sectionTransformer,
  setSerialArray,
  serialArray,
}: Props) => {
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileInfo, setFileInfo] = useState<Blob>();
  const [progress, setProgress] = useState(0);
  const [filePath, setFilePath] = useState<string>();

  useEffect(() => {
    setFormData({
      ...formData,
      [sectionTransformer]: {
        ...formData[sectionTransformer as keyof formRoutineTest],
        certificate_path: filePath,
      },
    });
  }, [filePath]);

  useEffect(() => {
    setFormData({
      ...formData,
      [sectionTransformer]: {
        ...formData[sectionTransformer as keyof formRoutineTest],
        serial_number: serialArray,
      },
    });
  }, [serialArray]);

  const updateState = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newArray = serialArray.map((item, i) => {
      if (index === i) {
        return { ...item, value: e.target.value };
      } else {
        return item;
      }
    });

    setSerialArray(newArray);
  };

  const addFieldSerial = () => {
    setSerialArray([...serialArray, { value: "" }]);
  };

  const deleteSerial = (index: number) => {
    const array = [...serialArray];
    array.splice(index, 1);
    setSerialArray(array);
  };

  const onClearFile = (): void => {
    setIsSuccess(false);
    setFormData({
      ...formData,
      [sectionTransformer]: {
        ...formData[sectionTransformer as keyof formRoutineTest],
        certificate_path: "",
      },
    });
    setProgress(0);
  };

  const uploadFile = async (file: Blob): Promise<void> => {
    setIsLoadingFile(true);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess);
      setFilePath(res);
    } catch (err) {
      // onClearFile();
    } finally {
      setIsLoadingFile(false);
    }
  };

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    setIsSuccess(false);
    setFileInfo(acceptedFiles[0]);
    uploadFile(acceptedFiles[0]);
  }, []);

  return (
    <>
      <Typography
        color='primary'
        sx={{
          fontWeight: "500",
          my: "10px",
        }}
        variant='h6'>
        {transformValue === "TC y TT" ? "TC" : transformValue}
      </Typography>
      <FormContainer>
        {serialArray.map((serial, index: number) => (
          <FormControl fullWidth sx={{ my: 2 }}>
            <CustomLabel>Número de serie</CustomLabel>
            {serialArray.length > 1 && (
              <DeleteIcon
                color='error'
                sx={{
                  right: "10px",
                  position: "absolute",
                  mb: 3,
                  cursor: "pointer",
                }}
                onClick={() => deleteSerial(index)}></DeleteIcon>
            )}
            <TextField
              placeholder='Número de serie'
              fullWidth
              name='value'
              type='text'
              onChange={updateState(index)}
              value={serial.value}
              sx={{ "& fieldset": { borderRadius: "12px" } }}
            />
          </FormControl>
        ))}

        <Button sx={{ my: 2 }} onClick={addFieldSerial} variant='contained'>
          Agregar serial +{" "}
        </Button>

        <FormControl fullWidth>
          <CustomLabel>Número de certificado</CustomLabel>
          <TextField
            placeholder='Número de certificado'
            fullWidth
            type='text'
            value={formData[sectionTransformer as keyof formRoutineTest]?.certificate_number}
            sx={{ "& fieldset": { borderRadius: "12px" } }}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              setFormData({
                ...formData,
                [sectionTransformer]: {
                  ...formData[sectionTransformer as keyof formRoutineTest],
                  certificate_number: target.value,
                },
              })
            }
          />
        </FormControl>
      </FormContainer>
      <FormControl fullWidth sx={{ mt: "20px" }}>
        <CustomLabel>Ente emisor</CustomLabel>
        <TextField
          placeholder='Ente emisor'
          type='text'
          sx={{ "& fieldset": { borderRadius: "12px" } }}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            setFormData({
              ...formData,
              [sectionTransformer]: {
                ...formData[sectionTransformer as keyof formRoutineTest],
                issuer_entity: target.value,
              },
            })
          }
          value={formData[sectionTransformer as keyof formRoutineTest]?.issuer_entity}
        />
      </FormControl>
      <Box sx={{ my: 5 }}>
        {isLoadingFile ||
        formData[sectionTransformer as keyof formRoutineTest]?.certificate_path ? (
          <CardPdf
            fileInfo={fileInfo as FileBlobType}
            progress={progress}
            isSuccess={isSuccess}
            onClearFile={onClearFile}
          />
        ) : (
          <DragZone onDrop={onDrop as FileBlobType} typeFile='pdf' />
        )}
      </Box>
    </>
  );
};

export default TransfoComponent;

type GridProps = {
  grid_columns?: number;
};

const FormContainer = styled("form")(({ grid_columns }: GridProps) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;
