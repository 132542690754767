import {
  Box,
  CardPdf,
  CustomAlert,
  DragZone,
  Grid,
  LoadingButton,
  Typography,
} from "@enerbit/base";
// @ts-ignore
import { api } from "@enerBit/base";
import { FileBlobType, MySeverityType } from "../../models/base";
import { FC, useState, useCallback, useEffect } from "react";
import { onSelectDocument } from "../../services/uploadFile";
import { useFormik } from "formik";
import { SearchCustomer } from "./SearchCustomer";
import { useAppDispatch, useAppSelector } from "../../store/libraryStore";
import { IRespFrontier } from "../../models/IRespFrontier";
import { string, object } from "yup";
import { sendForm } from "../../helpers/sendForm";
import { resetTableAddress } from "../../store/slices/table-address/tableAddress";

interface Props {
  url: string;
  selector: string;
}

export const PostSupplierPrevious: FC<Props> = ({ url }) => {
  const { selectedCustomers, open } = useAppSelector((state) => state.table);
  const dispatch = useAppDispatch();

  const [fileInfo, setFileInfo] = useState<Blob[] | Blob>([]);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
  const [textAlert, setTexAlert] = useState("");

  const formik = useFormik({
    initialValues: {
      file_path: "",
      form_data: {},
      frontier_id: "",
    },
    validationSchema: object({
      file_path: string().required("El documento es obligatorio."),
      frontier_id: string().required("La frontera es obligatoria."),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await sendForm(url, values);
        setSeverityAlert("success");
        setTexAlert("Documento guardado con éxito");
        dispatch(resetTableAddress());
        formik.resetForm();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: Blob[]) => {
      uploadFile(acceptedFiles[0]);
      setFileInfo(acceptedFiles[0]);
    },
    [formik]
  );

  const onClearFile = (): void => {
    setIsSuccess(false);
    formik.setFieldValue("file_path", "");
    setProgress(0);
  };

  const uploadFile = async (file: Blob | string): Promise<void> => {
    setIsLoadingFile(true);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess);
      formik.setFieldValue("file_path", res); // Update Formik state
    } finally {
      setIsLoadingFile(false);
    }
  };

  const closeAlert = () => {
    setTexAlert("");
    setSeverityAlert("");
  };

  useEffect(() => {
    if (selectedCustomers.length > 0 && !open) {
      const getFrontier = async () => {
        try {
          const { data } = await api.get<IRespFrontier>(
            `/assignees/frontiers?measurement_point_id=${selectedCustomers[0].measurement_point_id}`
          );
          const frontierID = data.items[0].id;
          formik.setFieldValue("frontier_id", frontierID);
        } catch (error) {
          console.log(error);
        }
      };
      getFrontier();
    }
  }, [selectedCustomers, open]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <SearchCustomer />
        {selectedCustomers.length > 0 && !open && (
          <>
            <Grid item>
              <Typography
                color="primary"
                sx={{
                  fontWeight: "300",
                }}
                variant="h4"
              >
                Hoja de vida comercializador previo
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {formik.values.file_path ? (
                <CardPdf
                  fileInfo={fileInfo as FileBlobType}
                  progress={progress}
                  isSuccess={isSuccess}
                  onClearFile={onClearFile}
                />
              ) : (
                <DragZone onDrop={onDrop as FileBlobType} typeFile="pdf" />
              )}
            </Grid>
            <Grid item xs={12} display="grid">
              <LoadingButton
                loading={isLoading}
                disabled={
                  !formik.values.file_path ||
                  !formik.values.frontier_id ||
                  isLoadingFile
                }
                type="submit"
                variant="contained"
                color="secondary"
              >
                Enviar
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </form>
  );
};
