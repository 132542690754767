// @ts-nocheck
//@ts-ignore
import { styled, OptionsList } from "@enerbit/base";
import { useEffect, useState } from "react";

import LibraryPage from "./library/LibraryPage";
import MeasurementPointPage from "./measurement-point/MeasurementPointPage";
import { useAppDispatch } from "../store/libraryStore";
import { resetList } from "../store/slices/table-address/tableAddress";

const DocumentsPage = () => {
  const dispatch = useAppDispatch();

  //Array que contiene las opciones de la lista
  const arrayOptions = [
    {
      name: "Cargue de documentos",
      component: <MeasurementPointPage />,
    },
    { name: "Consulta de documentos", component: <LibraryPage /> },
  ];

  //Estado que contiene la posición de la opción seleccionada de la lista
  const [positionOption, setPositionOption] = useState(1);

  useEffect(() => {
    dispatch(resetList());
  }, [positionOption]);

  return (
    <>
      <ContainerContent>
        <OptionsList
          arrayOptions={arrayOptions}
          sx={{ fontSize: "25px" }}
          positionOption={positionOption}
          setPositionOption={setPositionOption}
        />
        <ContainerComponent>
          {arrayOptions[positionOption].component}
        </ContainerComponent>
      </ContainerContent>
    </>
  );
};

export default DocumentsPage;

const ContainerContent = styled("div")`
  display: flex;
  width: 100%;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const ContainerComponent = styled("div")`
  width: 100%;
  /* margin-top: 70px; */
  padding: 36px 35px;
  background: #ffffff;
  overflow-y: scroll;
`;
