//@ts-ignore
import { api } from "@enerBit/base";
import React from "react";

export const onSelectDocument = async (
  e: Blob | string,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  url = "/uploads/inventory-documentation"
): Promise<string | undefined> => {
  const file = e;
  if (!file) {
    return;
  }
  const formData = new FormData();
  formData.append("file", file);
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  let retry = 0;
  let data;
  while (retry < 5) {
    try {
      const response = await api.post(url, formData, {
        headers,
        // @ts-ignore
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        // @ts-ignore
        onDownloadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
          setTimeout(() => setIsSuccess(true), 1000);
        },
      });
      data = response.data;
      break;
    } catch (error) {
      retry += 1;
    }
  }

  if (retry === 5) {
    throw new Error("Max retries exceeded");
  }

  return data.url;
};
