import {
  Button,
  Grid,
  MenuItem,
  SearchIcon,
  Select,
  TextField,
  Typography,
  useFormik,
} from "@enerbit/base";
import React from "react";
import { TechnicalEquipmentValidation } from "../../../../const/validations";
import { AppDispatch } from "../../../../store/libraryStore";
import { useDispatch } from "react-redux";
import {
  getDocsByBrand,
  getDocsByModelAndBrand,
  getWireTechnicalSheet,
} from "../../../../store/slices/librarySlice/thunks";
import { resetData } from "../../../../store/slices/librarySlice/librarySlice";
import { DocsByBrand, DocsByModelAndBrand } from "../../../../models/LibraryConsult";

interface Props {
  option: string;
}
const TechnicalEquipment = ({ option }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const equipmentList = [
    { title: "Medidor", value: "meter" },
    { title: "TC", value: "tc" },
    { title: "TT", value: "tt" },
    { title: "Bloque de prueba", value: "blockTest" },
    { title: "Módem", value: "modem" },
    { title: "Cable", value: "wire" },
  ];

  const formik = useFormik({
    initialValues: { model: "", brand: "", equipment: "", gauge: "", material: "" },
    validationSchema: TechnicalEquipmentValidation,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const fetchUrls: Record<string, string> = {
    tc: "/inventory-documentation/tc-technical-sheets",
    tt: "/inventory-documentation/tt-technical-sheets",
    modem: "/inventory-documentation/modem-technical-sheets",
    meter: "/inventory-documentation/meter-technical-sheets",
    blockTest: "/inventory-documentation/block-test-technical-sheets",
  };

  const fileNames: Record<string, string> = {
    tc: "Ficha técnica TC",
    tt: "Ficha técnica TT",
    modem: "Ficha técnica Módem",
    meter: "Ficha técnica Medidor",
    blockTest: "Ficha técnica bloque de pruebas",
  };

  const resetForm = () => {
    formik.values.brand = "";
    formik.values.model = "";
    formik.values.gauge = "";
    formik.values.material = "";
  };

  const handleSearch = () => {
    if (formik.values.equipment) {
      if (formik.values.equipment === "wire") {
        const data = { material: formik.values.material, gauge: formik.values.gauge };
        dispatch(getWireTechnicalSheet(data));
      } else if (formik.values.equipment !== "wire" && formik.values.equipment !== "blockTest") {
        const data: DocsByModelAndBrand = {
          url: fetchUrls[formik.values.equipment],
          brand: formik.values.brand,
          model: formik.values.model,
          fileName: fileNames[formik.values.equipment],
          option: option,
        };
        dispatch(getDocsByModelAndBrand(data));
      } else if (formik.values.equipment === "blockTest") {
        const data: DocsByBrand = {
          url: fetchUrls[formik.values.equipment],
          brand: formik.values.brand,
          fileName: fileNames[formik.values.equipment],
        };
        dispatch(getDocsByBrand(data));
      }
      resetForm();
    }
  };

  return (
    <form>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} mb={1}>
          <Typography mb={1} variant='body2'>
            Selecciona la ficha técnica
          </Typography>
          <Select
            id='equipment'
            name='equipment'
            error={Boolean(formik.values.equipment) && true}
            onChange={(event) => {
              formik.handleChange(event);
              resetForm();
              dispatch(resetData());
            }}
            fullWidth
            sx={{
              "& fieldset": { borderRadius: "12px", mt: "5px", height: "100%", border: "none" },
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              border: "1px solid #d0d5dd",
              height: "48px",
            }}>
            {equipmentList.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {formik.values.equipment && (
          <>
            {formik.values.equipment !== "wire" && (
              <Grid item xs={formik.values.equipment === "blockTest" ? 11 : 5.5}>
                <Typography mb={1} variant='body2'>
                  Marca
                </Typography>
                <TextField
                  placeholder='Escribe aquí la marca'
                  name='brand'
                  id='brand'
                  value={formik.values.brand}
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                  fullWidth
                  error={Boolean(formik.errors.brand) && true}
                  helperText={formik.errors.brand}
                  InputProps={{
                    sx: { padding: "14px 0", height: "100%" },
                  }}
                  sx={{
                    "& fieldset": {
                      borderRadius: "12px",
                      mt: "5px",
                      height: "100%",
                      border: "none",
                    },
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    border: "1px solid #d0d5dd",
                    height: "48px",
                  }}
                />
              </Grid>
            )}
            {formik.values.equipment === "wire" && (
              <Grid item xs={5.5}>
                <Typography mb={1} variant='body2'>
                  Material
                </Typography>
                <TextField
                  placeholder='Escribe aquí el material'
                  name='material'
                  id='material'
                  defaultValue={""}
                  value={formik.values.material}
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                  fullWidth
                  error={Boolean(formik.errors.material) && true}
                  helperText={formik.errors.material}
                  InputProps={{
                    sx: { padding: "14px 0", height: "100%" },
                  }}
                  sx={{
                    "& fieldset": {
                      borderRadius: "12px",
                      mt: "5px",
                      height: "100%",
                      border: "none",
                    },
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    border: "1px solid #d0d5dd",
                    height: "48px",
                  }}
                />
              </Grid>
            )}
            {formik.values.equipment !== "blockTest" && (
              <>
                {formik.values.equipment === "wire" && (
                  <Grid item xs={5.5}>
                    <Typography mb={1} variant='body2'>
                      Calibre
                    </Typography>
                    <TextField
                      fullWidth
                      name='gauge'
                      id='gauge'
                      type='number'
                      placeholder='Escribe aquí el calibre'
                      value={formik.values.gauge}
                      onChange={(event) => {
                        formik.handleChange(event);
                      }}
                      error={Boolean(formik.errors.gauge) && true}
                      helperText={formik.errors.gauge}
                      InputProps={{ sx: { padding: "14px 0", height: "100%" } }}
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid #d0d5dd",
                        height: "48px",
                      }}
                    />
                  </Grid>
                )}
                {formik.values.equipment !== "wire" && (
                  <Grid item xs={5.5}>
                    <Typography mb={1} variant='body2'>
                      Modelo
                    </Typography>
                    <TextField
                      fullWidth
                      name='model'
                      id='model'
                      placeholder='Escribe aquí el modelo'
                      value={formik.values.model}
                      onChange={(event) => {
                        formik.handleChange(event);
                      }}
                      error={Boolean(formik.errors.model) && true}
                      helperText={formik.errors.model}
                      InputProps={{ sx: { padding: "14px 0", height: "100%" } }}
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid #d0d5dd",
                        height: "48px",
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={1} style={{ display: "flex", alignItems: "center", marginTop: "26px" }}>
              <Button
                fullWidth
                sx={{ minWidth: "100%" }}
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}>
                <SearchIcon />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default TechnicalEquipment;
