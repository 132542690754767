import { DialogContent, Box, WarningIcon, DialogContentText, DialogActions, Button } from "@enerbit/base";
import { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../../common/components/StyledDialog";

interface ConfirmDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
  }

export const ConfirmDialog :FC<ConfirmDialogProps> = ({
    open,
    onClose,
    onConfirm,
  }) => {
  return (
    <StyledDialog open={open}>
      <StyledDialogTitle onClose={onClose} id={"id-confirm"} />
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <WarningIcon
            color="secondary"
            sx={{ textAlign: "center" }}
            fontSize={'large'}
          />
        </Box>
        <DialogContentText sx={{ color: "#53358E", textAlign: "center" }}>
          ¿Está seguro de generar la hoja de vida?
        </DialogContentText>
        <DialogContentText
          sx={{
            color: "#53358E",
            fontWeight: "bold",
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          Esto puede tomar unos minutos.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onConfirm} color="secondary" variant="contained">
          Aceptar
        </Button>
        <Button fullWidth onClick={onClose} color="primary" variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
