import { configureStore } from "@reduxjs/toolkit";
import librarySlice from "./slices/librarySlice/librarySlice";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import tableAddress from "./slices/table-address/tableAddress";

export const store = configureStore({
  reducer: {
    library: librarySlice,
    table: tableAddress,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
