import React, { useState } from "react";
import { Box, Typography, DragZone, Button } from "@enerbit/base";
import * as XLSX from "xlsx";
import bombi from "../../../../assets/cargando-enerbit.gif";
import ProcessCalibrationCertificateMassive from "./ProcessCalibationCertificateMassive";
import { FileBlobType } from "../../../../models/base";
import JSZip from "jszip";
import { saveAs } from "file-saver";

type ExcelRow = Record<string, unknown>; // Cambia este tipo según la estructura de tus datos

const CalibrationCertificateMassive = () => {
    const [excelData, setExcelData] = useState<ExcelRow[] | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [msgInformation, setMsgInformation] = useState("");
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const readUploadFile = (files: Array<Blob>) => {
        const file = files[0];
        if (file) {
            setUploadedFile(file as File);
            setIsProcessing(true); // Inicia la carga del archivo
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const data = event?.target?.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json<ExcelRow>(worksheet);

                    setExcelData(jsonData);  // Envía datos al segundo componente
                    setError(false);
                } catch (e) {
                    console.error("Error leyendo el archivo:", e);
                    setError(true);
                    setMsgInformation("Error al procesar el archivo. Asegúrate de que sea un archivo Excel válido.");
                } finally {
                    setIsProcessing(false); // Finaliza la carga del archivo
                }
            };

            reader.onerror = () => {
                setError(true);
                setMsgInformation("Error al leer el archivo. Intenta de nuevo.");
                setIsProcessing(false);
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const onDrop = (files: Blob[]) => {
        readUploadFile(files);
    };

    const onClearData = () => {
        setExcelData(null);
        setUploadedFile(null);
    };

    const generateExcelTemplate = () => {
        try {
            // Crear los datos de la plantilla en formato JSON
            const templateData = [
                { LEAD: "Ejemplo Lead", SERIAL: "Ejemplo Serial" },
                { LEAD: "", SERIAL: "" }, // Fila vacía
            ];
    
            // Crear una hoja de trabajo (worksheet) a partir de los datos
            const worksheet = XLSX.utils.json_to_sheet(templateData);
    
            // Crear el libro de trabajo (workbook)
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Plantilla");
    
            // Generar el archivo Excel como ArrayBuffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
    
            // Convertir el ArrayBuffer a un Blob
            const excelBlob = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
    
            // Descargar el archivo utilizando FileSaver
            saveAs(excelBlob, "plantilla-calibracion.xlsx");
        } catch (error) {
            console.error("Error al generar la plantilla Excel:", error);
        }
    };

    return (
        <>
            {!excelData ? (
                <Box sx={{ p: 3, backgroundColor: "#f9f9f9", borderRadius: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <Typography color="primary" sx={{ fontWeight: "500" }} variant="h6">
                            Consulta masiva
                        </Typography>
                        <Button
                            variant="text"
                            color="secondary"
                            sx={{ fontSize: "0.875rem", textDecoration: "underline" }}
                            onClick={generateExcelTemplate}
                        >
                            Descargar formato Excel
                        </Button>
                    </Box>

                    {!uploadedFile ? (
                        <DragZone onDrop={onDrop as FileBlobType} typeFile="xlsx" />
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: "#ffffff",
                                border: "1px solid #e0e0e0",
                                borderRadius: 1,
                                p: 2,
                                mb: 3,
                            }}
                        >
                            {/* Aquí puedes agregar más detalles del archivo cargado */}
                        </Box>
                    )}
                </Box>
            ) : (
                <ProcessCalibrationCertificateMassive
                    excelData={excelData}
                    onClearData={onClearData}
                    uploadedFile={uploadedFile}
                />
            )}
        </>
    );
};

export default CalibrationCertificateMassive;
