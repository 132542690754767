// @ts-ignore
import { api } from "@enerBit/base";

export const getAuthenticatedFile = async (url: string) => {
  const response = await api.get(
    `/inventory-documentation/authenticate-file?url_path=${url}`
  );

  return response.data.url as string;
};
