export type TLibraryCalibrationCertificate = 
  | "CalibrationCertificateSingle"
  | "CalibrationCertificateMassive";


export interface ILibraryCalibrationCertificate {
  name: string;
  value: TLibraryCalibrationCertificate;
}

export type TLibraryDocument =
  | "productMeterConformity"
  | "terminalBlockCertificate"
  | "wiresCertificate"
  | "cellsCertificate"
  | "meterCalibrationCertificate"
  | "tcCalibrationCertificate"
  | "ttCalibrationCertificate"
  | "technicalEquipment"
  | "policiesCGM"
  | "tlmSchema"
  | "opCommunications"
  | "infoProcess"
  | "meterValidationProcess"
  | "securityMechanism"
  | "dataSecurity"
  | "cgmDataProtection"
  | "maintenanceProcedure"
  | "anualReportCGM"
  | "recordDossierPreviousSupplier"
  | "recordDossier";

export interface ILibraryDocument {
  name: string;
  value: TLibraryDocument;
}
export const libraryDocumentsMassive: ILibraryCalibrationCertificate[] = [
  {
    name: "Certificado de calibración de medidores masivo",
    value: "CalibrationCertificateMassive",
  },
  {
    name: "Certificado de calibración de medidores uno a uno",
    value: "CalibrationCertificateSingle",
  }
]

export const libraryDocuments: ILibraryDocument[] = [
  {
    name: "Certificado de conformidad de producto de medidor",
    value: "productMeterConformity",
  },
  {
    name: "Certificado de conformidad de producto de bornera",
    value: "terminalBlockCertificate",
  },
  {
    name: "Certificado de conformidad de producto de cable",
    value: "wiresCertificate",
  },
  {
    name: "Certificado de conformidad de producto de celda",
    value: "cellsCertificate",
  },
  {
    name: "Certificado de calibración de medidores",
    value: "meterCalibrationCertificate",
  },
  {
    name: "Certificado de calibración de TC",
    value: "tcCalibrationCertificate",
  },
  {
    name: "Certificado de calibración de TT",
    value: "ttCalibrationCertificate",
  },
  // { name: "Certificado de pruebas de rutina", value: "routinesTestCertificate" },
  { name: "Ficha técnica equipos de medida", value: "technicalEquipment" },
  { name: "Parámetros procedimientos y políticas CGM", value: "policiesCGM" },
  { name: "Esquema TLM y comunicación medidores", value: "tlmSchema" },
  {
    name: "Condiciones op comunicaciones, medidores y cgm",
    value: "opCommunications",
  },
  {
    name: "Procedimiento para la crítica de información",
    value: "infoProcess",
  },
  {
    name: "Procedimiento validación de datos leídos de los medidores",
    value: "meterValidationProcess",
  },
  {
    name: "Mecanismo de protección, seguridad e integridad",
    value: "securityMechanism",
  },
  {
    name: "Procedimiento seguridad física e informática para protección de datos",
    value: "dataSecurity",
  },
  {
    name: "Procedimiento transmisión de datos CGM-ASIC",
    value: "cgmDataProtection",
  },
  {
    name: "Procedimiento de mantenimiento de la medida",
    value: "maintenanceProcedure",
  },
  { name: "Informe anual operación CGM", value: "anualReportCGM" },
  {
    name: "Hoja de vida comercializador previo",
    value: "recordDossierPreviousSupplier",
  },
  {
    name: "Hoja de vida",
    value: "recordDossier",
  },
];

export interface LibraryState {
  data: any[];
  isLoading: boolean;
  isEmpty: boolean;
  hasError: boolean;
  fileName: string;
}

export interface FormLabelInput {
  model?: string;
  brand?: string;
  gauge?: string;
  material?: string;
}

export interface DocsByModelAndBrand {
  url: string;
  model: string;
  brand: string;
  fileName: string;
  option: string;
}

export interface DocsByBrand {
  url: string;
  brand: string;
  fileName: string;
}
export interface DocsByBrandAndGauge {
  url: string;
  gauge: string;
  fileName: string;
  brand: string;
}
export interface DocsBySerialNumber {
  url: string;
  fileName: string;
  serial: string;
  option: string;
}
export interface DocsWithoutParams {
  url: string;
  fileName: string;
}

export interface DocumentsModel {
  items: DocumentItem[];
  page: number;
  size: number;
  total: number;
  pages: number;
  next_page: number;
  previous_page: number;
}

export interface DocumentItem {
  form_data: FormData;
  created_by: string;
  id: string;
  document_type_id: string;
  created_at: string;
  updated_at: string;
}

export interface FormData {
  certificate_path: string;
  model?: string;
  brand?: string;
  certificate_number?: string;
  gauge?: string;
  material?: string;
  number?: string;
  meter_serial?: string;
  active_import: ActiveImport | null;
  active_export: ActiveImport | null;
  reactive_import: ActiveImport | null;
  reactive_export: ActiveImport | null;
  active_export_import: ActiveImport | null;
  reactive_export_import: ActiveImport | null;
}

export interface ParsedDoc {
  authUrl: string;
  creationDate: string;
}

export interface LibraryOptionsModel {
  title: string;
  completeInfo?: boolean;
  fileName: string;
}

export interface ActiveImport {
  issue_date: string;
  calibration_date: string;
  certificate_number: string;
  issuer_entity: string;
  certificate_path: string;
}