import {
  Typography,
  Box,
  FormControl,
  styled,
  TextField,
  Select,
  MenuItem,
  LoadingButton,
  CustomAlert,
  SelectChangeEvent,
  //@ts-ignore
} from "@enerbit/base";
import React, { useState, useEffect } from "react";
import { sendForm } from "../../../../helpers/sendForm";
import { MySeverityType } from "../../../../models/base";
import { groupedForm, importForm, initialForm } from "./initialValues";
import MeterItem from "./MeterItem";

interface IoperatorId {
  operatorId: string;
}

const MeterCalibration = ({ operatorId }: IoperatorId) => {
  const [optionMeterSelected, setOptionMeterSelected] = useState("");
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [formData, setFormData] = useState<any>(initialForm);
  const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
  const [textAlert, setTexAlert] = useState("");

  const handleSubmitForm = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const url = "/inventory-documentation/meter-calibrations-certificate";
    const body = {
      form_data: formData,
      created_by: operatorId,
      document_type_id: "7279d9dc-55f5-11ed-9564-5e70b15957e9",
    };
    try {
      await sendForm(url, body);
      setSeverityAlert("success");
      setTexAlert("Documento guardado con éxito");
      setFormData(initialForm);
      //  resetForm();
    } catch (err) {
      setSeverityAlert("error");
      setTexAlert("No se pudo guardar el documento");
      return err;
    } finally {
      setIsLoadingForm(false);
    }
  };

  const closeAlert = () => {
    setTexAlert("");
    setSeverityAlert("");
  };

  const handleInputChange = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    section: string
  ): void => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [target.name]: target.value,
      },
    });
  };

  useEffect(() => {
    if (optionMeterSelected === "individual") {
      setFormData(groupedForm);
    } else {
      setFormData(importForm);
    }
  }, [optionMeterSelected]);

  return (
    <>
      <Typography
        color="primary"
        sx={{
          fontWeight: "300",
        }}
        variant="h4"
      >
        Certificado de calibración de medidores
      </Typography>
      {formData && (
        <Box component="form" onSubmit={handleSubmitForm}>
          <FormControl fullWidth sx={{ my: "20px" }}>
            <CustomLabel>
              ¿Cómo están agrupados los certificados de calibración?
            </CustomLabel>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ "& fieldset": { borderRadius: "12px" } }}
              value={optionMeterSelected}
              onChange={({ target }: SelectChangeEvent) =>
                setOptionMeterSelected(target.value)
              }
            >
              <MenuItem value="individual">
                Certificado individual para cada cuadrante (4)
              </MenuItem>
              <MenuItem value="import">
                Certificado importada - exportada (2)
              </MenuItem>
            </Select>
          </FormControl>

          {optionMeterSelected && (
            <Box>
              <FormControl fullWidth sx={{ mt: "20px" }}>
                <CustomLabel>Serial del medidor</CustomLabel>
                <TextField
                  placeholder="Número de medidor"
                  type="text"
                  name="meter_serial"
                  sx={{ "& fieldset": { borderRadius: "12px" } }}
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, meter_serial: target.value })
                  }
                  value={formData.meter_serial}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: "20px" }}>
                <CustomLabel>Norma aplicable </CustomLabel>
                <Select
                  placeholder="Norma aplicable"
                  type="text"
                  name="normativity"
                  value={formData.normativity}
                  sx={{ "& fieldset": { borderRadius: "12px" } }}
                  onChange={({
                    target,
                  }: React.ChangeEvent<HTMLInputElement> | any) =>
                    setFormData({ ...formData, normativity: target.value })
                  }
                >
                  <MenuItem value="ntc">Ntc</MenuItem>
                  <MenuItem value="equiv">Equivalente</MenuItem>
                </Select>
              </FormControl>
              {optionMeterSelected === "import" ? (
                <>
                  <MeterItem
                    title="Importada activa-reactiva"
                    label="Nro certificado activa importación"
                    sectionMeter="active_export_import"
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                  <MeterItem
                    title="Exportada activa-reactiva"
                    label="Nro certificado activa exportación"
                    sectionMeter="reactive_export_import"
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                </>
              ) : (
                <>
                  <MeterItem
                    title="Importada activa"
                    label="Nro certificado activa importación"
                    sectionMeter="active_import"
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                  <MeterItem
                    title="Exportada activa"
                    label="Nro certificado activa exportación"
                    sectionMeter="active_export"
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                  <MeterItem
                    title="Importada reactiva"
                    label="Nro certificado reactiva importación"
                    sectionMeter="reactive_import"
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                  <MeterItem
                    title="Exportada reactiva"
                    label="Nro certificado reactiva exportación"
                    sectionMeter="reactive_export"
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                </>
              )}
            </Box>
          )}
          {optionMeterSelected && (
            <LoadingButton
              className="grid_column_1_3"
              variant="contained"
              color="secondary"
              loading={isLoadingForm}
              fullWidth
              type="submit"
            >
              Enviar
            </LoadingButton>
          )}
        </Box>
      )}
      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </>
  );
};

export default MeterCalibration;

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;
