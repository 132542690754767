import {
	Box,
	MenuItem,
	Select,
	Typography,
	styled,
} from "@enerbit/base";
import CalibrationCertificate from "./CalibrationCertificateSingle";
import { libraryDocumentsMassive, TLibraryCalibrationCertificate } from "../../../../models/LibraryConsult";
import { useState } from "react";
import CalibrationCertificateMassive from "./CalibrationCertificateMassive";

const SelectCalibrationCertificate = () => {
	const [selectOption, setOption] = useState<TLibraryCalibrationCertificate | "">("");

	const consultForms: Record<string, JSX.Element> = {	
		CalibrationCertificateSingle: <CalibrationCertificate option={selectOption} />,
		CalibrationCertificateMassive: <CalibrationCertificateMassive/>,
	};

	return (
		<SearchContainer>
			<Box mt={"10px"} sx={{ width: "100%" }}>
    <Typography>¿Búsqueda masivo o uno a uno?</Typography>
    <Select
        value={selectOption}
        onChange={(e) => {
            const selectedOption = e.target.value as TLibraryCalibrationCertificate;
            if (selectedOption in consultForms) {
                setOption(selectedOption);
            }
        }}
        sx={{
            "& fieldset": {
                borderRadius: "12px",
                mt: "5px",
                height: "100%",
                border: "none",
            },
            width: "100%",  // Asegúrate de que ambos selectores tienen esta propiedad
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            border: "1px solid #d0d5dd",
            height: "48px",
        }}
    >
        {libraryDocumentsMassive.map((element, index) => (
            <MenuItem key={index} value={element.value}>
                {element.name}
            </MenuItem>
        ))}
    </Select>
</Box>
			{selectOption && (
				<Box mt={"20px"} sx={{ width: "100%" }}>
					{consultForms[selectOption]}
				</Box>
			)}
		</SearchContainer>
	);
};

export default SelectCalibrationCertificate;

const SearchContainer = styled("div")`
	margin-top: 20px;
	width: 100%;
	background-color: #f2f4f7;
	border-radius: 16px;
`;
