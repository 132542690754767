import {
	Button,
	Grid,
	SearchIcon,
	TextField,
	Typography,
	useFormik,
} from "@enerbit/base";
import { useDispatch } from "react-redux";
import { SerialValidation } from "../../../../const/validations";
import { DocsBySerialNumber } from "../../../../models/LibraryConsult";
import { AppDispatch } from "../../../../store/libraryStore";
import {
	getDocsBySerialNumber,
	getMeterCalibrationCertificate,
} from "../../../../store/slices/librarySlice/thunks";

interface Props {
	option: string;
}

const CalibrationCertificate = ({ option }: Props) => {
	const formik = useFormik({
		initialValues: { serial: "" },
		validationSchema: SerialValidation,
		onSubmit: () => {
			return;
		},
	});

	const dispatch = useDispatch<AppDispatch>();

	const fetchUrls: Record<string, string> = {
		meterCalibrationCertificate:
			"/inventory-documentation/meter-calibrations-certificate",
		tcCalibrationCertificate:
			"/inventory-documentation/tc-calibrations-certificate",
		ttCalibrationCertificate:
			"/inventory-documentation/tt-calibrations-certificate",
	};

	const fileNames: Record<string, string> = {
		meterCalibrationCertificate: "Certificado de calibración de medidores",
		tcCalibrationCertificate: "Certificado de calibración de TC",
		ttCalibrationCertificate: "Certificado de calibración de TT",
	};

	const labels: Record<string, string> = {
		meterCalibrationCertificate: "Número de medidor",
		tcCalibrationCertificate: "Número TC",
		ttCalibrationCertificate: "Número de TT",
	};

	const handleSearch = () => {
		if (option !== "meterCalibrationCertificate") {
			const data: DocsBySerialNumber = {
				option,
				serial: formik.values.serial,
				fileName: fileNames[option],
				url: fetchUrls[option],
			};
			dispatch(getDocsBySerialNumber(data));
			resetForm();
		} else {
			const data: DocsBySerialNumber = {
				option,
				serial: formik.values.serial,
				fileName: fileNames[option],
				url: fetchUrls[option],
			};
			dispatch(getMeterCalibrationCertificate(data));
			resetForm();
		}
	};

	const resetForm = () => {
		formik.values.serial = "";
	};

	const isTcOrTt = option.includes("tc") || option.includes("tt");

	return (
		<form>
			<Grid alignItems={"center"} container columnSpacing={2}>
				<Grid item xs={11}>
					<Typography mb={1} variant="body2">
						{labels[option]}
					</Typography>
					<TextField
						placeholder={
							isTcOrTt ? "Escribe aquí el serial" : "Escribe aquí el número"
						}
						name="serial"
						id="serial"
						type={isTcOrTt ? "string" : "number"}
						value={formik.values.serial}
						onChange={(event) => {
							formik.handleChange(event);
						}}
						fullWidth
						error={Boolean(formik.errors.serial) && true}
						helperText={formik.errors.serial}
						InputProps={{
							sx: { height: "100%" },
						}}
						sx={{
							"& fieldset": {
								borderRadius: "12px",
								mt: "5px",
								height: "100%",
								border: "none",
							},
							width: "100%",
							backgroundColor: "#ffffff",
							borderRadius: "12px",
							border: "1px solid #d0d5dd",
							height: "48px",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={1}
					sx={{ display: "flex",  alignItems: "center" }}
				>
					<Button
						fullWidth
						sx={{ minWidth: "unset" , mt:1.5}}
						variant="contained"
						onClick={(e) => {
							e.preventDefault();
							handleSearch();
						}}
					>
						<SearchIcon />
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default CalibrationCertificate;
