// @ts-ignore
import React, { useState } from "react";
import { Box, Typography, Button, LinearProgress } from "@enerbit/base";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import DeleteIcon from '@mui/icons-material/Delete';
import { processCalibrationMassive } from "../../../../services/processCalibrationMassive";

interface Props {
    excelData: any[];
    onClearData: () => void;
    uploadedFile: File | null;
}

const ProcessCalibrationCertificateMassive = ({ excelData, onClearData, uploadedFile }: Props) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [zipUrl, setZipUrl] = useState<string | null>(null);
    const [totalSize, setTotalSize] = useState(0);

    // Obtener el nombre del archivo ZIP con fecha y hora
    const getFileName = (): string => {
        const now = new Date();
        const date = now.toLocaleDateString("en-CA").replace(/-/g, "_"); // Formato YYYY_MM_DD
        const time = now.toLocaleTimeString("en-CA").replace(/:/g, "_").replace(/ /g, ""); // Formato HH_MM_SS
        return `calibrations_${date}_${time}.zip`;
    };

    // Convertir el tamaño de archivo a la unidad adecuada
    const formatSize = (sizeInBytes: number): string => {
        if (sizeInBytes >= 1024 * 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        } else if (sizeInBytes >= 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        }
    };

    // Procesar las filas del Excel
    const processExcelRows = async () => {
        if (!excelData || excelData.length === 0) {
            console.warn("No hay datos para procesar.");
            return;
        }

        const zip = new JSZip();
        const errorLogs: string[] = [];
        setIsProcessing(true);
        setProgress(0);

        for (let i = 0; i < excelData.length; i++) {
            const row = excelData[i];
            const { SERIAL: meter_serial, LEAD: lead_integrator_id } = row || {};

            try {
                const data = await processCalibrationMassive(meter_serial, lead_integrator_id);
                const { pdfs, file_name } = data;

                if (pdfs.length > 0) {
                    const folder = zip.folder(file_name);
                    for (const pdfUrl of pdfs) {
                        const pdfBlob = await fetch(pdfUrl.certificate_path).then((res) => res.blob());
                        const pdfName = pdfUrl.certificate_name.endsWith(".pdf")
                            ? pdfUrl.certificate_name
                            : `${pdfUrl.certificate_name}.pdf`;
                        folder?.file(pdfName, pdfBlob);
                    }
                } else {
                    errorLogs.push(`LEAD: ${lead_integrator_id}, SERIAL: ${meter_serial} - Datos no encontrados`);
                }
            } catch (error) {
                console.error("Error procesando fila:", error);
                errorLogs.push(`LEAD: ${lead_integrator_id}, SERIAL: ${meter_serial} - Falló al descargar`);
            }

            setProgress(Math.round(((i + 1) / excelData.length) * 100));
        }

        // Agregar archivo de errores al ZIP
        if (errorLogs.length > 0) {
            zip.file("certificados-fallidos.txt", errorLogs.join("\n"));
        }

        setIsProcessing(false);

        // Generar archivo ZIP
        zip.generateAsync({ type: "blob" }).then((content) => {
            const blobUrl = URL.createObjectURL(content);
            setZipUrl(blobUrl);
            setTotalSize(content.size);
        });
    };

    return (
        <Box>
            <Typography color="primary" sx={{ fontWeight: "500", my: 3 }} variant="h6">
                Datos cargados: Listos para procesar
            </Typography>

            {/* Información del archivo cargado */}
            {uploadedFile && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid #d0d5dd",
                        p: 2,
                        mb: 3,
                    }}
                >
                    <Box>
                        <Typography variant="body2" sx={{ fontWeight: "500" }}>
                            {uploadedFile.name}
                        </Typography>
                        <Typography color="textSecondary">
                            {formatSize(uploadedFile.size)}
                        </Typography>
                        <Typography color="textSecondary">
                            Total Registros: {excelData?.length}
                        </Typography>
                    </Box>
                    <Button variant="outlined" color="error" onClick={onClearData}>
                        <DeleteIcon />
                    </Button>
                </Box>
            )}

            {/* Barra de progreso */}
            {isProcessing && (
                <Box sx={{ my: 3 }}>
                    <Typography variant="body2" color="textSecondary">{`Progreso: ${progress}%`}</Typography>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            )}

            {/* Botón para procesar el archivo Excel */}
            <Button variant="contained" color="primary" onClick={processExcelRows} disabled={isProcessing}>
                {isProcessing ? "Procesando..." : "Procesar Excel"}
            </Button>

            {/* Información del archivo ZIP generado */}
            {zipUrl && (
                <Box
                    sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "1px solid #d0d5dd",
                        p: 2,
                        mb: 3,
                    }}
                >
                    <Typography variant="body1" color="primary">
                        Resultado: Certificados en archivo ZIP
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Tamaño: {formatSize(totalSize)}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => saveAs(zipUrl, getFileName())}>
                        Descargar ZIP
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default ProcessCalibrationCertificateMassive;