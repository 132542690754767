import { string } from "yup/lib/locale";

export const initialForm = {
  meter_serial: "",
  is_grouped: true,
  normativity: "ntc",
  active_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  active_export: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  reactive_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  reactive_export: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  active_export_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  reactive_export_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
};

export const importForm = {
  meter_serial: "",
  is_grouped: false,
  normativity: "ntc",
  active_export_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  reactive_export_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
};

export const groupedForm = {
  meter_serial: "",
  normativity: "ntc",
  is_grouped: true,
  active_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  active_export: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  reactive_import: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
  reactive_export: {
    issue_date: "",
    calibration_date: "",
    certificate_number: "",
    issuer_entity: "",
    certificate_path: "",
  },
};
