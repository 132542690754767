import {
  Box,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  MoreVertIcon,
  RemoveRedEyeOutlinedIcon,
  Typography,
  styled,
} from "@enerbit/base";
// @ts-ignore
import { api } from "@enerBit/base";
import { FC, useEffect, useState } from "react";
import { SearchCustomer } from "../../../../common/components/SearchCustomer";
import { IRespFrontier } from "../../../../models/IRespFrontier";
import { useAppSelector } from "../../../../store/libraryStore";
import {
  IRespRecordDossierPreviousSupplier,
  RecordDossierPreviousSupplierItem,
} from "../../../../models/IRespRecordDossierPreviousSupplier";

interface Props {
  option: string;
}

export const GetSupplierPrevious: FC<Props> = ({ option }) => {
  const { selectedCustomers, open } = useAppSelector((state) => state.table);

  const [document, setDocument] =
    useState<RecordDossierPreviousSupplierItem | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedCustomers.length > 0 && !open) {
      const getFrontier = async () => {
        try {
          setIsLoading(true);
          const { data: dataFrontier } = await api.get<IRespFrontier>(
            `/assignees/frontiers?measurement_point_id=${selectedCustomers[0].measurement_point_id}`
          );
          const frontierID = dataFrontier.items[0].id;
          const { data: dataDocument } =
            await api.get<IRespRecordDossierPreviousSupplier>(
              `documentation/record-dossier-previous-supplier?frontier_id=${frontierID}&page=0&size=50`
            );
          const currentDocument = dataDocument.items.slice(-1)[0];
          setDocument(currentDocument);
          const { data } = await api.get<{ url: string }>(
            `/documentation/authenticate-file?url_path=${currentDocument.file_path}`
          );
          setDownloadLink(data.url);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      getFrontier();
    }
  }, [selectedCustomers, open]);

  return (
    <Grid container spacing={2}>
      <SearchCustomer />
      {document && (
        <>
          <Grid item xs={12}>
            <Typography
              color={"primary"}
              sx={{ fontSize: "16px", fontWeight: 700, marginBottom: "12px" }}
            >
              Hoja de vida comercializador previo
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ActionsContainer>
              <FileContainer>
                <PdfIcon>PDF</PdfIcon>
                <Box>
                  <Typography fontWeight={700} variant="subtitle1">
                    {`Hoja de vida comercializador previo.pdf`}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      color: "#667085",
                      fontSize: "14px",
                    }}
                  >
                    {`Fecha de creación: ${document?.created_at}`}
                  </Typography>
                </Box>
              </FileContainer>
              <MenuContainer>
                <div>
                  <Box
                    sx={{ cursor: "pointer" }}
                    id="basic-button"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </Box>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    PaperProps={{ sx: { width: "190px" } }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <DownloadLink href={downloadLink} target="_blank">
                      <MenuItem
                        sx={{ display: "flex", alignItems: "center" }}
                        onClick={handleClose}
                      >
                        <ItemContainer>
                          <RemoveRedEyeOutlinedIcon />
                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Previsualizar
                          </Typography>
                        </ItemContainer>
                      </MenuItem>
                    </DownloadLink>
                  </Menu>
                </div>
              </MenuContainer>
            </ActionsContainer>
          </Grid>
        </>
      )}
      {isLoading && !document && (
        <Grid item xs={12}>
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </Grid>
      )}
    </Grid>
  );
};

const PdfIcon = styled("div")`
  width: 45px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;

const FileContainer = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ActionsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const MenuContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const DownloadLink = styled("a")`
  text-decoration: none;
  color: #000000;
`;

const LoaderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
