import { Pagination } from "@enerbit/base";
import { useAppDispatch, useAppSelector } from "../../store/libraryStore";
import { setPage } from "../../store/slices/table-address/tableAddress";


export const CustomPagination = () => {

    const { rowCount, page, loading } = useAppSelector(state => state.table);
    
    const dispatch = useAppDispatch();

    return (
        <Pagination
            disabled={loading}
            shape="rounded"
            color="primary"
            count={rowCount}
            page={page + 1}
            onChange={(_, value) => dispatch(setPage(value - 1))}
        />
    );
};