import React, { useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
  SearchIcon,
} from "@enerbit/base";
import { CustomerModal } from "./CustomerModal";
import { useAppDispatch, useAppSelector } from "../../store/libraryStore";
import {
  setPage,
  setPageSize,
  openModal,
  setFilter,
} from "../../store/slices/table-address/tableAddress";

export const SearchCustomer = () => {
  const { loading, open, filter } = useAppSelector((state) => state.table);

  const dispatch = useAppDispatch();

  const getCustomer = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(filter)) {
      dispatch(setPage(0));
      dispatch(setPageSize(5));
      dispatch(openModal());
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Grid container spacing={3}>
          <Grid item flex={1}>
            <TextField
              fullWidth
              onKeyDown={(e) => e.key === "Enter" && getCustomer()}
              name={"filter"}
              value={filter}
              onChange={(e) => dispatch(setFilter(e.target.value))}
              sx={{ "& fieldset": { borderRadius: "14px" } }}
              type={"email"}
              id={"email"}
              disabled={loading}
              variant="outlined"
              placeholder="Buscar cliente"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} thickness={6} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Button
              id="search-lead"
              color="primary"
              variant="contained"
              onClick={getCustomer}
              disabled={loading}
            >
              <SearchIcon />
            </Button>
          </Grid>
        </Grid>
      </FormControl>
      {open && <CustomerModal />}
    </Grid>
  );
};
