import { Button, Grid, TextField, Typography, SearchIcon } from "@enerbit/base";
import { useFormik } from "formik";
import { ModelAndBrandValidation } from "../../../../const/validations";
import {
  DocsByBrand,
  DocsByBrandAndGauge,
  DocsByModelAndBrand,
} from "../../../../models/LibraryConsult";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/libraryStore";
import {
  getDocsByBrand,
  getDocsByBrandAndGauge,
  getDocsByModelAndBrand,
} from "../../../../store/slices/librarySlice/thunks";

interface Props {
  option: string;
}

const ProductConformity = ({ option }: Props) => {
  const formik = useFormik({
    initialValues: { model: "", brand: "", gauge: "", material: "" },
    validationSchema: ModelAndBrandValidation,
    onSubmit: () => {
      return;
    },
  });

  const dispatch = useDispatch<AppDispatch>();

  const fetchUrls: Record<string, string> = {
    terminalBlockCertificate: "/inventory-documentation/borners-compliance-certificate",
    wiresCertificate: "/inventory-documentation/wires-compliances-certificate",
    cellsCertificate: "/inventory-documentation/cells-compliance-certificate",
    productMeterConformity: "/inventory-documentation/meter-compliance-certificate",
  };

  const fileNames: Record<string, string> = {
    terminalBlockCertificate: "Certificado de conformidad de producto: Bornera",
    wiresCertificate: "Certificado de conformidad de producto: Cable",
    cellsCertificate: "Certificado de conformidad de producto: Celda",
    productMeterConformity: "Certificado de conformidad de producto: Medidor",
  };

  const handleSearch = () => {
    if (option === "terminalBlockCertificate") {
      const data: DocsByBrand = {
        url: fetchUrls[option],
        fileName: fileNames[option],
        brand: formik.values.brand,
      };
      dispatch(getDocsByBrand(data));
    } else if (option === "wiresCertificate") {
      const data: DocsByBrandAndGauge = {
        brand: formik.values.brand,
        gauge: formik.values.gauge,
        url: fetchUrls[option],
        fileName: fileNames[option],
      };
      dispatch(getDocsByBrandAndGauge(data));
    } else {
      const data: DocsByModelAndBrand = {
        url: fetchUrls[option],
        brand: formik.values.brand,
        model: formik.values.model,
        fileName: fileNames[option],
        option: option,
      };
      dispatch(getDocsByModelAndBrand(data));
    }
    resetForm();
  };

  const resetForm = () => {
    formik.values.brand = "";
    formik.values.model = "";
    formik.values.gauge = "";
    formik.values.material = "";
  };

  return (
    <form>
      <Grid container columnSpacing={2}>
        <Grid item xs={option === "terminalBlockCertificate" ? 11 : 5.5}>
          <Typography mb={1} variant='body2'>
            Marca
          </Typography>
          <TextField
            placeholder='Escribe aquí la marca'
            name='brand'
            id='brand'
            value={formik.values.brand}
            onChange={(event) => {
              formik.handleChange(event);
            }}
            fullWidth
            error={Boolean(formik.errors.brand) && true}
            helperText={formik.errors.brand}
            InputProps={{
              sx: { padding: "14px 0", height: "100%" },
            }}
            sx={{
              "& fieldset": { borderRadius: "12px", mt: "5px", height: "100%", border: "none" },
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              border: "1px solid #d0d5dd",
              height: "48px",
            }}
          />
        </Grid>
        {option !== "terminalBlockCertificate" && (
          <>
            {option !== "wiresCertificate" && (
              <Grid item xs={5.5}>
                <Typography mb={1} variant='body2'>
                  Modelo
                </Typography>
                <TextField
                  fullWidth
                  name='model'
                  id='model'
                  placeholder='Escribe aquí el modelo'
                  value={formik.values.model}
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                  error={Boolean(formik.errors.model) && true}
                  helperText={formik.errors.model}
                  InputProps={{ sx: { padding: "14px 0", height: "100%" } }}
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    border: "1px solid #d0d5dd",
                    height: "48px",
                  }}
                />
              </Grid>
            )}
            {option === "wiresCertificate" && (
              <Grid item xs={5.5}>
                <Typography mb={1} variant='body2'>
                  Calibre
                </Typography>
                <TextField
                  fullWidth
                  name='gauge'
                  id='gauge'
                  placeholder='Escribe aquí el calibre'
                  value={formik.values.gauge}
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                  error={Boolean(formik.errors.gauge) && true}
                  helperText={formik.errors.gauge}
                  InputProps={{ sx: { padding: "14px 0", height: "100%" } }}
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    border: "1px solid #d0d5dd",
                    height: "48px",
                  }}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={1} style={{ display: "flex", alignItems: "center", marginTop: "26px" }}>
          <Button
            fullWidth
            sx={{ minWidth: "unset" }}
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              handleSearch();
            }}>
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductConformity;
