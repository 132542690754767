import { FC } from "react";
import TcTtCertificates from "../../common/components/TcTtCertificate";
import TechnicalSheet from "../../common/components/TechnicalSheet";
import MeterCalibration from "./components/meter-calibration/MeterCalibration";
import RoutineTest from "./components/routine-test/RoutineTest";
import { PostSupplierPrevious } from "../../common/components/PostSupplierPrevious";

interface Props {
  attachmentTypeSelector: string;
  operatorId: string;
}

export const DetermineFormComponent: FC<Props> = ({
  attachmentTypeSelector,
  operatorId,
}) => {

  const componentMap: { [key: string]: JSX.Element } = {
    routine_test: <RoutineTest operatorId={operatorId} />,
    meter_calibrations: <MeterCalibration operatorId={operatorId} />,
    copy_technical_documentation_measurement_equipment_meter: (
      <TechnicalSheet
        operatorId={operatorId}
        selector={attachmentTypeSelector}
        url="/inventory-documentation/meter-technical-sheets"
        label="Ficha técnica Medidor"
      />
    ),
    copy_technical_documentation_measurement_equipment_tc: (
      <TechnicalSheet
        operatorId={operatorId}
        selector={attachmentTypeSelector}
        url="/inventory-documentation/tc-technical-sheets"
        label="Ficha técnica TC"
      />
    ),
    copy_technical_documentation_measurement_equipment_tt: (
      <TechnicalSheet
        operatorId={operatorId}
        selector={attachmentTypeSelector}
        url="/inventory-documentation/tt-technical-sheets"
        label="Ficha técnica TT"
      />
    ),
    copy_technical_documentation_measurement_block_test: (
      <TechnicalSheet
        operatorId={operatorId}
        selector={attachmentTypeSelector}
        url="/inventory-documentation/block-test-technical-sheets"
        label="Ficha técnica bloque de prueba"
      />
    ),
    copy_technical_documentation_measurement_modem: (
      <TechnicalSheet
        operatorId={operatorId}
        selector={attachmentTypeSelector}
        url="/inventory-documentation/modem-technical-sheets"
        label="Ficha técnica modem"
      />
    ),
    copy_technical_documentation_measurement_equipment_cable: (
      <TechnicalSheet
        operatorId={operatorId}
        selector={attachmentTypeSelector}
        isWire={true}
        url="/inventory-documentation/wire-technical-sheets"
        label="Ficha técnica cable"
      />
    ),
    tt_calibrations: (
      <TcTtCertificates
        operatorId={operatorId}
        url="/inventory-documentation/tt-calibrations-certificate"
        nameTransformer="TT"
        selector={attachmentTypeSelector}
      />
    ),
    tc_calibrations: (
      <TcTtCertificates
        operatorId={operatorId}
        url="/inventory-documentation/tc-calibrations-certificate"
        nameTransformer="TC"
        selector={attachmentTypeSelector}
      />
    ),
    record_dosser_previous_supplier: (
      <PostSupplierPrevious
        url="/documentation/record-dossier-previous-supplier"
        selector={attachmentTypeSelector}
      />
    ),
  };

  return componentMap[attachmentTypeSelector] || null; // Retorna null si no hay coincidencia
};
