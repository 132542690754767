// @ts-ignore
import { api } from "@enerBit/base";
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	LoadingButton,
	Typography,
	enerbitColors,
	styled,
	useSnackbar,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { SearchCustomer } from "../../../../common/components/SearchCustomer";
import type { IRespStatusFrontier } from "../../../../models/IRespStatusFrontier";
import { useAppSelector } from "../../../../store/libraryStore";
import { ConfirmDialog } from "./ConfirmDialog";

interface Condition {
	name: string;
	status: boolean;
}

interface Props {
	option: string;
}

const nameMapping: { [key: string]: string } = {
	certificate_inspection_initial: "Acta de inspección previa",
	certificate_installation: "Acta de instalación",
	measurement_type_frontier: "Tipo de medición frontera",
	document_letter_xm_frontier: "Carta documento XM frontera",
	document_memory_calculator: "Memoria de cálculo",
	operator: "Operador",
	professional_registration_operator: "Registro profesional del operador",
};

export const GetRecord: FC<Props> = () => {
	const { selectedCustomers, open } = useAppSelector((state) => state.table);
	const [openModal, setOpenModal] = useState(false);
	const [frontierID, setFrontierID] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [conditionsList, setConditionsList] = useState<Condition[]>([]);
	const [recordDossierFile, setRecordDossierFile] = useState("");
	const [countdown, setCountdown] = useState(0);
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [stateButtons, setStateButtons] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const fetchData = async (endpoint: string, params: string) => {
		setIsLoading(true);
		try {
			const { data } = await api.get(`${endpoint}${params}`);
			return data;
		} catch (error) {
			console.error(error);
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	const downloadFile = (url: string, filename: string) => {
		const link = document.createElement("a");
		link.href = url;
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const checkForRecordDossierFile = async (is_generate?: boolean) => {
		const data = await fetchData(
			"/documentation/record-dossier-file?frontier_id=",
			frontierID,
		);
		if (data && data.items.length > 0) {
			setRecordDossierFile(data.items[0].file_path);
			if (is_generate) {
				enqueueSnackbar("La hoja de vida se generó con éxito.", {
					variant: "success",
				});
			}
		} else {
			setRecordDossierFile("");
			if (is_generate) {
				enqueueSnackbar(
					"No se pudo generar el archivo en este momento. Por favor, comuníquese con el personal a cargo para obtener asistencia.",
					{ variant: "error" },
				);
			}
		}
	};

	const checkButtonStatus = (data: IRespStatusFrontier): boolean => {
		if (!data.document_letter_xm_frontier) {
			return false;
		}

		const notRequiredIfDirect = new Set([
			"certificate_inspection_initial",
			"document_memory_calculator",
		]);

		const isDirectMeasurement = data.measurement_type_frontier === "directa";

		return Object.entries(data).every(([key, value]) => {
			if (key === "document_letter_xm_frontier") {
				return true;
			}

			if (isDirectMeasurement && notRequiredIfDirect.has(key)) {
				return true;
			}

			return value === true || typeof value === "string";
		});
	};

	const buildConditionsList = (data: IRespStatusFrontier): void => {
		setConditionsList([]);

		const excludedKeys = [
			"certificate_inspection_initial",
			"document_memory_calculator",
		];
		const conditions: Condition[] = Object.entries(data)
			.filter(
				([key]) =>
					!(
						(data.measurement_type_frontier === "directa" &&
							excludedKeys.includes(key)) ||
						key === "measurement_type_frontier"
					),
			)
			.map(([key, value]) => ({
				name: nameMapping[key] || key,
				status: value === true || typeof value === "string",
			}));

		setConditionsList(conditions);
	};

	const handleTime = async () => {
		handleClose();
		setIsLoading(true);
		setCountdown(180);

		await api.post(`/documentation/record-dossier?id=${frontierID}`);

		const interval = setInterval(() => {
			setCountdown((prevCountdown) => {
				const newCountdown = prevCountdown - 1;
				if (newCountdown <= 0) {
					clearInterval(interval);
					handleHV();
					return 0;
				}
				return newCountdown;
			});
		}, 1000);
	};

	const handleHV = async () => {
		await checkForRecordDossierFile(true);
		if (recordDossierFile) {
			await getAuthenticatedRecord(recordDossierFile, true);
		}
	};

	const getAuthenticatedRecord = async (url: string, is_generate?: boolean) => {
		setIsAuthenticating(true);
		try {
			const response = await api.get(
				`/documentation/authenticate-file?url_path=${url}`,
			);
			if (response.data.url) {
				downloadFile(response.data.url, frontierID);
				enqueueSnackbar("La hoja de vida se descargó con éxito.", {
					variant: "success",
				});
			}
		} catch (error) {
			enqueueSnackbar(
				"No se pudo descargar la hoja de vida, inténtelo de nuevo.",
				{ variant: "error" },
			);
			console.error(error);
		} finally {
			setIsAuthenticating(false);
		}
	};

	const formatCountdown = (countdown: number) => {
		const minutes = Math.floor(countdown / 60);
		const seconds = countdown % 60;
		return `${minutes}:${seconds.toString().padStart(2, "0")}`; // Formato MM:SS
	};

	useEffect(() => {
		const initialize = async () => {
			if (selectedCustomers.length > 0 && !open) {
				const dataFrontier = await fetchData(
					"/assignees/frontiers?measurement_point_id=",
					selectedCustomers[0].measurement_point_id,
				);
				if (dataFrontier && dataFrontier.items.length > 0) {
					const currentFrontierID = dataFrontier.items[0].id;
					setFrontierID(currentFrontierID);

					const dataFrontierStatus = await fetchData(
						"reports/status-frontier?frontier_id=",
						currentFrontierID,
					);
					if (dataFrontierStatus) {
						const state = checkButtonStatus(dataFrontierStatus);
						console.log(state);
						setStateButtons(state);
						buildConditionsList(dataFrontierStatus);
					}
				}
			}
		};

		initialize();
	}, [selectedCustomers, open, frontierID]);

	useEffect(() => {
		if (frontierID) {
			checkForRecordDossierFile();
		}
	}, [frontierID]);

	return (
		<Grid container spacing={2}>
			<SearchCustomer />
			{conditionsList.length > 0 && (
				<>
					<Grid item xs={12}>
						<Typography
							color={"primary"}
							sx={{ fontSize: "24px", fontWeight: 700 }}
						>
							Hoja de vida
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							<b>Recuerda:</b> Para la generación de la hoja de vida, debe
							cumplir con las siguientes condiciones, en caso de que falte
							alguna condición la hoja de vida no será generada.
						</Typography>
					</Grid>
					{conditionsList.map((condition: Condition, index) => (
						<Grid item xs={12} key={`${condition.name}-${frontierID}`}>
							<FormControlLabel
								control={<Checkbox checked={condition.status} />}
								label={condition.name}
							/>
						</Grid>
					))}
					{recordDossierFile && (
						<Grid item xs={12} display="grid">
							<LoadingButton
								variant="contained"
								color="primary"
								loading={isAuthenticating}
								disabled={isLoading || !frontierID || !stateButtons}
								onClick={() => getAuthenticatedRecord(recordDossierFile ?? "")}
							>
								Descargar Hoja de Vida
							</LoadingButton>
						</Grid>
					)}
					<Grid item xs={12} display="grid">
						<LoadingButton
							variant="contained"
							color="secondary"
							onClick={handleOpen}
							disabled={!frontierID || !stateButtons}
							loading={isLoading || countdown > 0}
						>
							{isLoading ? "Generando..." : "Generar Hoja de Vida"}
						</LoadingButton>
						<ConfirmDialog
							open={openModal}
							onClose={handleClose}
							onConfirm={handleTime}
						/>
						{countdown > 0 && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									color: enerbitColors.primary.main, // Color verde neón para el texto
									fontSize: "48px", // Tamaño de la fuente grande
									letterSpacing: "2px", // Espaciado de caracteres
									padding: "10px",
									margin: "20px 0",
									borderRadius: "5px",
								}}
							>
								<Typography variant="h4">
									{countdown > 0 ? `${formatCountdown(countdown)}` : "00:00"}
								</Typography>
								<Typography>
									Es el tiempo de espera de la generación de hoja de vida
								</Typography>
							</Box>
						)}
					</Grid>
				</>
			)}
			{conditionsList.length === 0 && isLoading && (
				<Grid item xs={12}>
					<LoaderContainer>
						<CircularProgress />
					</LoaderContainer>
				</Grid>
			)}
		</Grid>
	);
};

const LoaderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
