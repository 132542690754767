import {
  NavSidebar,
  NotiProvider,
  PrivateRoute,
  ThemeConfig,
  initEnviroment,
  styled,
} from "@enerbit/base";
import DocumentsPage from "./pages/DocumentsPage";
import { Provider } from "react-redux";
import { store } from "./store/libraryStore";
import "./styles/App.scss";


const App = () => {
  return (
    <ThemeConfig>
      < NavSidebar />
      <NotiProvider>
        <Container>
          <Provider store={store}>
            <DocumentsPage />
          </Provider>
        </Container>
      </NotiProvider>
    </ThemeConfig>
  );
};

export default App;

const Container = styled("div")`
  display: flex;
  position: absolute;
  top: 70px;
  width: calc(100% - 70px);
  height: calc(100vh - 70px);
  margin-left: 70px;
`;
