import { useCallback, useState, useEffect, ChangeEvent } from "react";

import {
  Box,
  FormControl,
  styled,
  TextField,
  LocalizationProvider,
  DatePicker,
  DragZone,
  CardPdf,
  DeleteIcon,
  Button,
  Select,
  MenuItem,
  dayjs,
  AdapterDayjs,
} from "@enerbit/base";
import { onSelectDocument } from "../../services/uploadFile";

import {
  formDataCertificateTcTt,
  IArrayFormData,
} from "../../models/itemCertifcate";
import { FileBlobType } from "../../models/base";

interface Props {
  nameTransformer: string;
  formData: IArrayFormData;
  field: formDataCertificateTcTt;
  updateState: (
    index: number
  ) => (e: ChangeEvent<HTMLInputElement> | any) => void;
  setFormData: Function;
  index: number;
}

const ItemCertificate = ({
  nameTransformer,
  formData,
  field,
  updateState,
  setFormData,
  index,
}: Props) => {
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [fileInfo, setFileInfo] = useState<Blob>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [filePath, setFilePath] = useState<string>();
  const [progress, setProgress] = useState(0);

  const deleteItem = (index: number): void => {
    setFormData(formData.filter((_, i) => index !== i));
  };

  const uploadFile = async (file: Blob | string): Promise<void> => {
    setIsLoadingFile(true);
    try {
      const res = await onSelectDocument(file, setProgress, setIsSuccess);
      setFilePath(res);
    } catch (err) {
      // onClearFile();
    } finally {
      setIsLoadingFile(false);
    }
  };

  useEffect(() => {
    setFormData(
      formData.map((item, i) =>
        index === i
          ? {
              ...item,
              form_data: {
                ...item["form_data"],
                certificate_path: filePath,
              },
            }
          : item
      )
    );
  }, [filePath]);

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    console.log("accepted files: ", acceptedFiles[0]);
    setIsSuccess(false);
    setFileInfo(acceptedFiles[0]);
    uploadFile(acceptedFiles[0]);
  }, []);

  const onChangeDate = (index: number) => (newValue: Date | any) => {
    setFormData(
      formData.map((item, i) =>
        index === i
          ? {
              ...item,
              form_data: {
                ...item["form_data"],
                issue_date: newValue.format("YYYY/MM/DD").replace(/\//g, "-"),
              },
            }
          : item
      )
    );
  };

  const onClearFile = (): void => {
    setIsSuccess(false);
    setFilePath("");
    setProgress(0);
  };

  return (
    <div>
      {formData.length > 1 && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={() => deleteItem(index)}
          >
            <DeleteIcon />
          </Button>
        </Box>
      )}

      <FormControl fullWidth sx={{ mt: "20px" }}>
        <CustomLabel>{`Número de ${nameTransformer}`}</CustomLabel>
        <TextField
          placeholder={`Número de ${nameTransformer}`}
          type="text"
          name="number"
          value={field.form_data.number}
          onChange={updateState(index)}
          sx={{ "& fieldset": { borderRadius: "12px" } }}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: "20px" }}>
        <CustomLabel>Norma aplicable </CustomLabel>
        <Select
          placeholder="Norma aplicable"
          type="text"
          name="normativity"
          value={field.form_data.normativity}
          onChange={updateState(index)}
          sx={{ "& fieldset": { borderRadius: "12px" } }}
        >
          <MenuItem value="ntc">Ntc</MenuItem>
          <MenuItem value="equiv">Equivalente</MenuItem>
        </Select>
      </FormControl>

      <FormContainer grid_columns={2}>
        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>Número del certficado primera relación</CustomLabel>
          <TextField
            value={field.form_data.number_first_relation}
            placeholder="Número del certficado primera relación"
            type="text"
            name="number_first_relation"
            onChange={updateState(index)}
            sx={{ "& fieldset": { borderRadius: "12px" } }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>Número del certficado segunda relación</CustomLabel>
          <TextField
            value={field.form_data.number_second_relation}
            placeholder="Número del certficado segunda relación"
            type="text"
            name="number_second_relation"
            onChange={updateState(index)}
            sx={{ "& fieldset": { borderRadius: "12px" } }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: "20px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomLabel>Fecha de emisión</CustomLabel>

            <DatePicker
              value={
                field.form_data.issue_date
                  ? dayjs(field.form_data.issue_date)
                  : null
              }
              maxDate={dayjs()}
              format="YYYY/MM/DD"
              onChange={onChangeDate(index)}
              slotProps={{
                textField: {
                  sx: {
                    "& fieldset": { borderRadius: "14px" },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth sx={{ mt: "20px" }}>
          <CustomLabel>Ente emisor</CustomLabel>
          <TextField
            value={field.form_data.issuer_entity}
            placeholder="Ente emisor"
            type="text"
            onChange={updateState(index)}
            name="issuer_entity"
            sx={{ "& fieldset": { borderRadius: "12px" } }}
          />
        </FormControl>
      </FormContainer>

      <Box sx={{ my: 5 }}>
        {isLoadingFile || (field?.form_data.certificate_path && filePath) ? (
          <CardPdf
            fileInfo={fileInfo as FileBlobType}
            progress={progress}
            isSuccess={isSuccess}
            onClearFile={onClearFile}
          />
        ) : (
          <DragZone onDrop={onDrop as FileBlobType} typeFile="pdf" />
        )}
      </Box>
    </div>
  );
};

export default ItemCertificate;

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;

type Grid = {
  grid_columns: number;
};

const FormContainer = styled("form")(({ grid_columns }: Grid) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
