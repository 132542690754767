import {
  formSchema_meterComplianceCertificate, //10.2 Certificado de conformidad producto de medidor
  formSchema_tc_tt_compliances, //10.3 Certificado de conformidad producto tcs-tts
  formSchema_bornersComplianceCertificate, //10.4 Certificado de conformidad producto borneras
  formSchema_wiresCompliances, //10.5 Certificado de conformidad producto cable
  formSchema_cellsCompliances, //10.6 Certificado de conformidad producto celda
  formSchema_meterCalibrations, //10.7 Certificado de calibracion medidores
  formSchema_ttCalibrations, //10.8.2 Certificado de calibración tt
  formSchema_tcCalibrations, //10.8.1 Certificado de calibración tc
  formSchema_routineTests, //10.9 Certificado de pruebas de rutina
  formSchema_maintenanceProcedureMeasurements, //10.10 Procedimiennto de mantenimiento medida
  formSchema_parametersProceduresPoliciesCgm, //10.16 Parametros procedimientos politicas cgm
  formSchema_tlmDiagramMeterCommunication, //10.17 Esquema diagrama TLM y comunicacion medidores
  formSchema_conditionsOpCommunicationsMetersCgm, //10.18 Condiciones op comunicaciones, medidores y cgm
  formSchema_procedureCritiqueInformation, //10.19 Procedimiento para la critica de informacion
  formSchema_physicalComputerSecurityDataProtection, //10.22 Seguridad fisica e informatica para proteccion de datos
  formSchema_cgmAsicDataTransmissionProcedure, //10.23 Procedimiento de transmision de datos cgm asic
  formSchema_lastReadLogFileSentCgmAsic, //10.24 Ultima lectura de log file enviado a cgm y asic
  formSchema_cgmAnnualOperatingReport, //10.25 Reporte anual de operacion cgm
  formSchema10_20,
  formSchema10_22,
  formSchema10_26,
  formSchema10_27,
  formSchema10_28,
  formSchema10_31,
  validationSchema10_20,
  validationSchema10_26,
  validationSchema10_27,
  validationSchema10_28,
  validationSchema10_31,
  validationSchema_meterComplianceCertificate, //10.2 Certificado de conformidad producto de medidor
  validationSchema_tc_tt_compliances, //10.3 Certificado de conformidad producto tcs-tts
  validationSchema_bornersComplianceCertificate, //10.4 Certificado de conformidad producto borneras
  validationSchema_wiresCompliances, //10.5 Certificado de conformidad producto cable
  validationSchema_cellsCompliances, //10.6 Certificado de conformidad producto celda
  validationSchema_meterCalibrations, //10.7 Certificado de calibracion medidores
  validationSchema_ttCalibrations, //10.8.2 Certificado de calibración tt
  validationSchema_tcCalibrations, //10.8.1 Certificado de calibración tc
  validationSchema_routineTests, //10.9 Certificado de pruebas de rutina
  validationSchema_maintenanceProcedureMeasurements, //10.10 Procedimiennto de mantenimiento medida
  validationSchema_copyTechnicalDocumentationMeasurementEquipment, //10.15 Copia de documentacion tecnica equipos de medida
  validationSchema_parametersProceduresPoliciesCgm, //10.16 Parametros procedimientos politicas cgm
  validationSchema_tlmDiagramMeterCommunication, //10.17 Esquema diagrama TLM y comunicacion medidores
  validationSchema_conditionsOpCommunicationsMetersCgm, //10.18 Condiciones op comunicaciones, medidores y cgm
  validationSchema_physicalComputerSecurityDataProtection,
  validationSchema_procedureCritiqueInformation,
  validationSchema_lastReadLogFileSentCgmAsic,
  validationSchema_cgmAnnualOperatingReport,
  validationSchema_cgmAsicDataTransmissionProcedure,
  formSchema_copyTechnicalDocumentationMeter,
  formSchema_copyTechnicalDocumentationTt,
  formSchema_copyTechnicalDocumentationBlockTest,
  formSchema_copyTechnicalDocumentationBlockModem,
  formSchemMaintenance, //10.21 Mecanismo de protección, seguridad e integridad
} from "../schema-validation/validations";

//Objeto que tendrá los selectores (valores) de los tipos de adjuntos
export const attachmentTypeInputs: { [key: string]: any } = {
  //10.2 Certificado de conformidad producto de medidor
  meter_compliance_certificate: {
    fields: [
      {
        label: "Certificado de conformidad producto de medidor",
        type: "title",
      },
      { label: "Marca", type: "text", nameValue: "brand", grid: "1_2" },
      {
        label: "Modelo (Referencia)",
        type: "text",
        nameValue: "model",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
        isIssueDate: true,
      },
      {
        label: "Fecha de vencimiento",
        type: "date",
        nameValue: "expiration_date",
        grid: "2_3",
        isExpirationDate: true,
      },
      {
        label: "Numero del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_meterComplianceCertificate,
    validationSchema: validationSchema_meterComplianceCertificate,
    endPoint: "/inventory-documentation/meter-compliance-certificate",
  },
  //10.3 Certificado de conformidad producto tcs-tts
  tc_compliances: {
    fields: [
      { label: "Certificado de conformidad producto tcs", type: "title" },
      { label: "Marca", type: "text", nameValue: "brand", grid: "1_2" },
      {
        label: "Modelo (Referencia)",
        type: "text",
        nameValue: "model",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
        isIssueDate: true,
      },
      {
        label: "Fecha de vencimiento",
        type: "date",
        nameValue: "expiration_date",
        grid: "2_3",
        isExpirationDate: true,
      },
      {
        label: "Numero del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_tc_tt_compliances,
    validationSchema: validationSchema_tc_tt_compliances,
    endPoint: "/inventory-documentation/tc-compliances-certificate",
  },
  tt_compliances: {
    fields: [
      { label: "Certificado de conformidad producto tts", type: "title" },
      { label: "Marca", type: "text", nameValue: "brand", grid: "1_2" },
      {
        label: "Modelo (Referencia)",
        type: "text",
        nameValue: "model",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
        isIssueDate: true,
      },
      {
        label: "Fecha de vencimiento",
        type: "date",
        nameValue: "expiration_date",
        grid: "2_3",
        isExpirationDate: true,
      },
      {
        label: "Numero del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_tc_tt_compliances,
    validationSchema: validationSchema_tc_tt_compliances,
    endPoint: "/inventory-documentation/tt-compliances-certificate",
  },
  //10.4 Certificado conformidad producto borneras
  borners_compliance_certificate: {
    fields: [
      { label: "Certificado conformidad producto borneras", type: "title" },
      { label: "Marca", type: "text", nameValue: "brand", grid: "1_2" },
      {
        label: "Modelo (Referencia)",
        type: "text",
        nameValue: "model",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
        isIssueDate: true,
      },
      {
        label: "Fecha de vencimiento",
        type: "date",
        nameValue: "expiration_date",
        grid: "2_3",
        isExpirationDate: true,
      },
      {
        label: "Numero del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_bornersComplianceCertificate,
    validationSchema: validationSchema_bornersComplianceCertificate,
    endPoint: "/inventory-documentation/borners-compliance-certificate",
  },
  //10.5 Certificado conformidad producto cable
  wires_compliances: {
    fields: [
      { label: "Certificado conformidad producto cable", type: "title" },
      { label: "Marca", type: "text", nameValue: "brand", grid: "1_2" },
      {
        label: "Calibre",
        type: "select",
        nameValue: "gauge",
        selectOptions: [
          { value: "8", nameValue: "", label: "8" },
          { value: "10", label: "10" },
          { value: "12", label: "12" },
          { value: "14", label: "14" },
        ],
        grid: "2_3",
      },
      { label: "Material", type: "text", nameValue: "material", grid: "1_2" },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "2_3",
        isIssueDate: true,
      },
      {
        label: "Fecha de vencimiento",
        type: "date",
        nameValue: "expiration_date",
        grid: "1_2",
        isExpirationDate: true,
      },
      {
        label: "Numero del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "2_3",
      },
      { label: "Ente emisor", type: "text", nameValue: "issuer_entity" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_wiresCompliances,
    validationSchema: validationSchema_wiresCompliances,
    endPoint: "/inventory-documentation/wires-compliances-certificate",
  },

  //10.6 Certificado conformidad producto celda
  cells_compliances: {
    fields: [
      { label: "Certificado conformidad producto celda", type: "title" },
      { label: "Marca", type: "text", nameValue: "brand", grid: "1_2" },
      {
        label: "Modelo (Referencia, campo opcional)",
        type: "text",
        nameValue: "model",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
      },
      {
        label: "Fecha de vencimiento",
        type: "date",
        nameValue: "expiration_date",
        grid: "2_3",
      },
      {
        label: "Numero del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_cellsCompliances,
    validationSchema: validationSchema_cellsCompliances,
    endPoint: "/inventory-documentation/cells-compliance-certificate",
  },

  //10.7 Certificado de calibracion de medidores
  meter_calibrations: {
    fields: [
      { label: "Certificado de calibración medidores", type: "title" },
      { label: "Número de medidor", type: "text", nameValue: "meterNumber" },
      { label: "Ente emisor", type: "text", nameValue: "issuer_entity" },
      {
        label: "¿Cómo están agrupados los certificados de calibración?",
        type: "select",
        nameValue: "groupedCertificates",
        selectOptions: [
          {
            value: "individual_single_certificate",
            label: "Certificado individual para cada cuadrante (4)",
          },
          {
            value: "import_export_certificate",
            label: "Certificado importada - exportada (2)",
          },
        ],
      },
    ],
    validationSchema: validationSchema_meterCalibrations,
    formSchema: formSchema_meterCalibrations,
  },

  //10.8.1 Certificado de calibración TC
  tc_calibrations: {
    fields: [
      { label: "Certificado de calibración TC", type: "title" },
      { label: "Número TC", type: "text", nameValue: "number" },
      {
        label: "Número del certficado primera relación",
        type: "text",
        nameValue: "number_first_relation",
        grid: "1_2",
      },
      {
        label: "Número del certificado segunda relación",
        type: "text",
        nameValue: "number_second_relation",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    validationSchema: validationSchema_tcCalibrations,
    formSchema: formSchema_tcCalibrations,
    endPoint: "/inventory-documentation/tc-calibrations-certificate",
  },

  //10.8.2 Certificado de calibración TT
  tt_calibrations: {
    fields: [
      { label: "Certificado de calibración TT", type: "title" },
      { label: "Número TT", type: "text", nameValue: "number" },
      {
        label: "Número del certficado primera relación",
        type: "text",
        nameValue: "number_first_relation",
        grid: "1_2",
      },
      {
        label: "Número del certificado segunda relación",
        type: "text",
        nameValue: "number_second_relation",
        grid: "2_3",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "1_2",
      },
      {
        label: "Ente emisor",
        type: "text",
        nameValue: "issuer_entity",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_ttCalibrations,
    validationSchema: validationSchema_ttCalibrations,
    endPoint: "/inventory-documentation/tt-calibrations-certificate",
  },

  //10.9 Pruebas de rutina
  routine_test: {
    fields: [
      { label: "Pruebas de rutina", type: "title" },
      { label: "Certificado de prueba de rutina TC", type: "subtitle" },
      {
        label: "Aplica para",
        type: "select",
        nameValue: "routineTests",
        selectOptions: [
          { value: "tc", label: "TC" },
          { value: "tt", label: "TT" },
          { value: "tc_tt", label: "TC y TT" },
        ],
        // grid: "1_2",
      },
      {
        label: "Número de serie",
        type: "text",
        nameValue: "serialNumber",
        grid: "2_3",
      },
      {
        label: "Número del certificado",
        type: "text",
        nameValue: "certificate_number",
        grid: "1_2",
      },
      {
        label: "Fecha de emisión",
        type: "date",
        nameValue: "issue_date",
        grid: "2_3",
      },
      { label: "Ente emisor", type: "text", nameValue: "issuer_entity" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_routineTests,
    validationSchema: validationSchema_routineTests,
    endPoint: "/inventory-documentation/rountine-tests-certificate",
  },

  //10.10 Procdeimiento de mantenimiento medida
  maintennace_procedure_measurements: {
    fields: [
      { label: "Procedimiento de mantenimiento medida", type: "title" },
      { label: "Certificado de prueba de rutina TC", type: "subtitle" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_maintenanceProcedureMeasurements,
    validationSchema: validationSchema_maintenanceProcedureMeasurements,
    endPoint:
      "/inventory-documentation/maintenance-procedure-measurements-certificate",
  },

  //10.15 Copia documentación técnica equipos de medida
  copy_technical_documentation_measurement_equipment_meter: {
    fields: [
      { label: "Copia documentacion técnica Medidor", type: "title" },
      { label: "Medidor", type: "subtitle" },
      { label: "Marca", type: "text", nameValue: "meterBrand", grid: "1_2" },
      { label: "Modelo", type: "text", nameValue: "meterModel", grid: "2_3" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_copyTechnicalDocumentationMeter,
    validationSchema:
      validationSchema_copyTechnicalDocumentationMeasurementEquipment,
  },
  copy_technical_documentation_measurement_equipment_tc: {
    fields: [
      { label: "Copia documentacion técnica TC", type: "title" },
      { label: "TC", type: "subtitle" },
      { label: "Marca", type: "text", nameValue: "tccBrand", grid: "1_2" },
      { label: "Modelo", type: "text", nameValue: "tccModel", grid: "2_3" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_copyTechnicalDocumentationTt,
    validationSchema:
      validationSchema_copyTechnicalDocumentationMeasurementEquipment,
  },
  copy_technical_documentation_measurement_equipment_tt: {
    fields: [
      { label: "Copia documentacion técnica TT", type: "title" },
      { label: "TT", type: "subtitle" },
      { label: "Marca", type: "text", nameValue: "ttBrand", grid: "1_2" },
      { label: "Modelo", type: "text", nameValue: "ttModel", grid: "2_3" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_copyTechnicalDocumentationTt,
    validationSchema:
      validationSchema_copyTechnicalDocumentationMeasurementEquipment,
  },

  copy_technical_documentation_measurement_block_test: {
    fields: [
      { label: "Copia documentacion técnica Bloque de prueba", type: "title" },
      { label: "Bloque de prueba", type: "subtitle" },
      {
        label: "Marca",
        type: "text",
        nameValue: "testBlockBrand",
        grid: "1_2",
      },
      {
        label: "Modelo",
        type: "text",
        nameValue: "testBlockModel",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_copyTechnicalDocumentationBlockTest,
    validationSchema:
      validationSchema_copyTechnicalDocumentationMeasurementEquipment,
  },

  copy_technical_documentation_measurement_modem: {
    fields: [
      { label: "Copia documentacion técnica Módem", type: "title" },
      { label: "Módem", type: "subtitle" },
      {
        label: "Marca",
        type: "text",
        nameValue: "brand",
        grid: "1_2",
      },
      {
        label: "Modelo",
        type: "text",
        nameValue: "model",
        grid: "2_3",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_copyTechnicalDocumentationBlockModem,
    validationSchema:
      validationSchema_copyTechnicalDocumentationMeasurementEquipment,
  },

  copy_technical_documentation_measurement_equipment_cable: {
    fields: [
      { label: "Copia documentacion técnica Cable", type: "title" },
      { label: "Cable", type: "subtitle" },
      { label: "Marca", type: "text", nameValue: "cableBrand", grid: "1_2" },
      { label: "Modelo", type: "text", nameValue: "cableModel", grid: "2_3" },
      {
        label: "Calibre",
        type: "select",
        nameValue: "gauge",
        grid: "1_2",
        selectOptions: [
          { value: "8", label: "8" },
          { value: "10", label: "10" },
          { value: "12", label: "14" },
          { value: "14", label: "14" },
        ],
      },
      { label: "Marca", type: "text", nameValue: "brand", grid: "2_3" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
      // type: "select",
      //   nameValue: "routineTests",
      //   selectOptions: [
      //     { value: "tc", label: "TC" },
      //     { value: "tt", label: "TT" },
      //     { value: "tc_tt", label: "TC y TT" },
      //   ],
    ],
    formSchema: formSchema_copyTechnicalDocumentationTt,
    validationSchema:
      validationSchema_copyTechnicalDocumentationMeasurementEquipment,
  },

  //10.16 Parametros procedimientos y politicas CGM
  parameters_procedures_policies_cgm: {
    fields: [
      { label: "Parámetros, procedimientos y políticas CGM", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_parametersProceduresPoliciesCgm,
    validationSchema: validationSchema_parametersProceduresPoliciesCgm,
    endPoint:
      "/inventory-documentation/parameters-procedures-policies-cgm-certificate",
  },

  //10.17 Esquema diagrama tlm y comunicacion medidores
  tlm_diagram_meter_communication: {
    fields: [
      { label: "Esquema diagrama TLM y comunicación medidores", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_tlmDiagramMeterCommunication,
    validationSchema: validationSchema_tlmDiagramMeterCommunication,
    endPoint:
      "/inventory-documentation/tlm-diagram-meter-communication-certificate",
  },

  conditions_op_communications_meters_cgm: {
    fields: [
      {
        label: "Condiciones OP comunicaciones, medidores y CGM",
        type: "title",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_conditionsOpCommunicationsMetersCgm,
    validationSchema: validationSchema_conditionsOpCommunicationsMetersCgm,
    endPoint:
      "/inventory-documentation/conditions-op-communications-meters-cgm-certificate",
  },

  information_criticism_procedure: {
    fields: [
      { label: "Procedimiento para la crítica de información", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_procedureCritiqueInformation,
    validationSchema: validationSchema_procedureCritiqueInformation,
    endPoint:
      "/inventory-documentation/procedure-critique-information-certificate",
  },

  //10.20 Procedimiento val de datos leidos de los medidores
  procedure_val_data_read_meters: {
    fields: [
      {
        label: "Procedimiento validación de  datos leídos de los medidores",
        type: "title",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema10_20,
    validationSchema: validationSchema10_20,
    endPoint:
      "inventory-documentation/procedure-validation-data-read-from-meters",
  },

  //10.21 Mecanismo de protección, seguridad e integridad
  physical_computer_security_data_protection: {
    fields: [
      {
        label: "Mecanismo de protección, seguridad e integridad",
        type: "title",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_physicalComputerSecurityDataProtection,
    validationSchema: validationSchema_physicalComputerSecurityDataProtection,
    endPoint:
      "/inventory-documentation/protection-security-integrity-mechanism",
  },

  //10.22 P seguridad fisica e informática para protección datos
  physical_and_information_security_protection_data: {
    fields: [
      {
        label:
          "Procedimiento seguridad física e informática para protección datos",
        type: "title",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_physicalComputerSecurityDataProtection,
    validationSchema: validationSchema_physicalComputerSecurityDataProtection,
    endPoint:
      "/inventory-documentation/p-physical-computer-security-data-protection-certificate",
  },

  //10.23 Procedimiento transmisión datos CGM-ASIC
  cgm_asic_data_transmission_procedure: {
    fields: [
      { label: "Procedimiento transmisión datos CGM-ASIC", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_cgmAsicDataTransmissionProcedure,
    validationSchema: validationSchema_cgmAsicDataTransmissionProcedure,
    endPoint:
      "/inventory-documentation/cgm-asic-data-transmission-procedure-certificate",
  },

  //10.24 Ultimo archivo registro lectura enviado CGM al ASIC
  last_file_reading_record_sent_cgm_to_asic: {
    fields: [
      {
        label: "Último archivo registro lectura enviado CGM al ASIC",
        type: "title",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_lastReadLogFileSentCgmAsic,
    validationSchema: validationSchema_lastReadLogFileSentCgmAsic,
    endPoint:
      "/inventory-documentation/last-read-log-file-sent-cgm-asic-certificate",
  },

  //10.25 Informe anual operacion CGM
  annual_operation_report_cgm: {
    fields: [
      { label: "Informe anual operación CGM", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema_cgmAnnualOperatingReport,
    validationSchema: validationSchema_cgmAnnualOperatingReport,
    endPoint: "/inventory-documentation/cgm-annual-report-certificate",
  },

  //10.26 Proyección de consumo en KWh-MES
  consumption_projection_in_kwh_month: {
    fields: [
      { label: "Proyección de consumo en KWh-MES", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema10_26,
    validationSchema: validationSchema10_26,
  },

  //10.27 Información consumo promedio KWh-MES ultimos doce meses
  information_average_consumption: {
    fields: [
      {
        label: "Información consumo promedio KWh-MES últimos doce meses",
        type: "title",
      },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema10_27,
    validationSchema: validationSchema10_27,
  },

  //10.28 Consumo mes a mes
  consumption_month_to_month: {
    fields: [
      { label: "Consumo mes a mes", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema10_28,
    validationSchema: validationSchema10_28,
  },

  //10.31 Reporte consumos XM DGP
  report_consumptions_xm_dgp: {
    fields: [
      { label: "Reporte consumos XM DGP", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    formSchema: formSchema10_31,
    validationSchema: validationSchema10_31,
  },
  maintenance_procedure_measurements: {
    fields: [
      { label: "Procedimiento de mantenimiento de la medida", type: "title" },
      { label: "", type: "dragAndDrop", nameValue: "certificate_path" },
    ],
    endPoint:
      "/inventory-documentation/maintenance-procedure-measurements-certificate",
    formSchema: formSchemMaintenance,
  },

  meters_batch: {
    fields: [
      { label: "Creación masiva de medidores", type: "title" },
      {
        label: "",
        type: "dragAndDrop",
        nameValue: "certificate_path",
        typeFile: "csv",
      },
    ],
    formSchema: formSchema10_31,
    endPoint: "/meters/meters-batch",
    validationSchema: validationSchema10_31,
  },
};
